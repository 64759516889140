
import { computed, defineComponent } from "vue";
import Label from "../../shared/components/Label.vue";
import Box from "../../shared/components/Box.vue";
import Translation from "../../shared/components/Translation.vue";
import TranslationAlt from "../../shared/components/TranslationAlt.vue";
import CopyrightHover from "../../shared/components/CopyrightHover.vue";

export default defineComponent({
    name: "EntityHero",
    props: {
        entity: {
            type: Object,
            required: true,
        },
        pageHeading: {
            type: Boolean,
            default: false,
        },
    },
    components: { Label, Box, Translation, TranslationAlt, CopyrightHover },
    setup(props) {
        const entityPath = computed(() => {
            // slicing /api/entities/123 to /entities/123
            return props.entity["@id"].slice(4, props.entity["@id"].length);
        });
        return { entityPath };
    },
});
