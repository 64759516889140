import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77bcee81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "forgot-password-popup mb-5" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/')))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("landing-page.view.lost-password")), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.submit(_ctx.email)), ["prevent"]))
        }, [
          _createVNode(_component_Input, {
            modelValue: _ctx.v$.email.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.email.$model) = $event)),
            type: "email",
            label: _ctx.$t('landing-page.view.email'),
            invalid: _ctx.v$.email.email.$invalid && _ctx.v$.email.$dirty,
            errorMessage: _ctx.$t('errors.email-is-invalid')
          }, null, 8, ["modelValue", "label", "invalid", "errorMessage"]),
          _createElementVNode("button", {
            disabled: _ctx.v$.$invalid,
            type: "submit",
            class: "form"
          }, [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("landing-page.view.send-me-password")), 1))
              : (_openBlock(), _createBlock(_component_Spinner, {
                  key: 1,
                  size: "sm",
                  style: {"margin":"auto"}
                }))
          ], 8, _hoisted_2),
          _createVNode(_component_Alert, { message: _ctx.backendError }, null, 8, ["message"])
        ], 32)
      ])
    ]),
    _: 1
  }))
}