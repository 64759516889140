import { UserDto } from "../dto/user";
import { useAPI } from "../api";
import { useApiResource } from "../api-resource";
import { AxiosResponse } from "axios";
import { Resource, ResourceData, Whole } from "../util/types";
import { HydraCollection } from "../dto/hydra";

export const USER_ROLES: string[] = ["ROLE_PORTAL_USER", "ROLE_EDITOR", "ROLE_ADMIN"];

export interface PatchUserData {
    role?: string;
    fullName?: string;
}

export interface UserQueryParams {
    page: string;
}

export function useUsers() {
    const { http } = useAPI();

    const getUsers = (params: UserQueryParams): Promise<AxiosResponse<HydraCollection<UserDto>>> => {
        return http.get<HydraCollection<UserDto>>(`/api/admin/users`, { params });
    };

    const createUser = (data: ResourceData<UserDto>): Promise<AxiosResponse<Resource>> => {
        return http.post<UserDto>(`/api/admin/users/create`, data);
    };

    const patchUser = (id: string, data: PatchUserData): Promise<AxiosResponse<Whole<PatchUserData>>> => {
        return http.patch<UserDto>(`/api/users/${id}`, data);
    };

    const patchUserAsAdmin = (id: string, data: PatchUserData): Promise<AxiosResponse<Whole<PatchUserData>>> => {
        return http.patch<UserDto>(`/api/admin/users/${id}`, data);
    };

    const getUser = (id: string): Promise<AxiosResponse<UserDto>> => {
        return http.get<UserDto>(`/api/users/${id}`);
    };

    const deleteUser = (id: string): Promise<AxiosResponse<void>> => {
        return http.patch(`/api/admin/users/${id}`);
    };

    return {
        getUsers,
        getUser,
        patchUser,
        patchUserAsAdmin,
        createUser,
        deleteUser,
    };
}

export function useUsersResource(params: UserQueryParams) {
    return useApiResource<UserDto, UserQueryParams>(`/api/admin/users`, params);
}
