import { nextTick } from "vue";
import { useRoute } from "vue-router";
import { parseToken } from "./token";
import { useAPI } from "../api";
import { TokenDataDto, TokenDto } from "../dto/token";

export type SocialMediaType = "google" | "facebook";

export function useLogin() {
    const route = useRoute();
    const { http, updateCredentials } = useAPI();

    function startSocialMediaLogin(socialMedia: SocialMediaType): void {
        window.location.href = `/api/auth/${socialMedia}/start`;
    }

    function handleSocialMediaLogin(socialMedia: SocialMediaType, returnUrl?: string): Promise<void> {
        const code = route.query.code;
        returnUrl = returnUrl ?? (typeof route.query.return === "string" ? route.query.return : undefined);
        if (!code) return Promise.reject(new Error(`Missing {code} parameter from social media`));

        return http.post(`/api/auth/${socialMedia}/connect`, { code }).then((res) => {
            updateCredentials(res.data);
            nextTick().then(() => {
                if (returnUrl) {
                    window.location.href = returnUrl;
                }
                if (window.localStorage.getItem("kunsten_login_from") === "admin") {
                    window.location.href = "/admin";
                }
            });
        });
    }

    function login(email: string, password: string): Promise<TokenDataDto> {
        return http.post<TokenDto>(`/api/auth/token`, { email, password }).then((res) => {
            updateCredentials(res.data);
            return parseToken(res.data.token);
        });
    }

    return {
        login,
        startSocialMediaLogin,
        handleSocialMediaLogin,
    };
}
