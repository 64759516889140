import { onMounted, onBeforeUnmount, ref, Ref, readonly } from "vue";

// Breakpoint values support by the app
const BREAKPOINT_VALUES = {
    mobile: "(max-width: 768px)",
    tablet: "(min-width: 769px)",
    desktop: "(min-width: 1024px)",
    widescreen: "(min-width: 1216px)",
    fullhd: "(min-width: 1408px)",
};

export type BreakpointName = keyof typeof BREAKPOINT_VALUES;
export type BreakpointsMap = { [T in BreakpointName]: T };

/**
 * Object containing all of the breakpoint keys, with same value.
 * Provides enum style interface for retrieving breakpoint key to avoid usage of magic strings.
 */
export const BREAKPOINTS = Object.fromEntries(
    Object.keys(BREAKPOINT_VALUES).map((key) => [key, key])
) as BreakpointsMap;

export interface UseViewport {
    matches: Ref<boolean>;
    media: MediaQueryList;
}

/**
 * Given breakpoint name returns whether it is matched.
 *
 * @param {BreakpointName} breakpoint Name of the breakpoint, @see BREAKPOINTS
 * @returns {UseViewport} Reactive ref whether breakpoint matches and MediaQueryList for low level usage or outside of vue reactivity
 */
export function useViewport(breakpoint: BreakpointName): UseViewport {
    const media = window.matchMedia(BREAKPOINT_VALUES[breakpoint]);
    const matches = ref<boolean>(media.matches);

    const listener = (e: MediaQueryListEvent) => {
        matches.value = e.matches;
    };

    onMounted(() => {
        // Despite addListener being deprecated addEventListener is not support in Safari yet...
        // https://caniuse.com/mdn-api_mediaquerylist
        media.addListener(listener);
    });

    onBeforeUnmount(() => {
        media.removeListener(listener);
    });

    return { matches: readonly(matches), media };
}
