
import { computed, nextTick, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { changeLocale, LOCALE_DATA, SUPPORTED_LOCALES } from "@/i18n";
import { createPopper, Instance as PopperInstance } from "@popperjs/core";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
    name: "LanguageSwitcher",
    data() {
        return {
            open: false,
            popper: null as PopperInstance | null,
        };
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const i18n = useI18n();
        const select = (locale: string) => {
            changeLocale(locale);
            if (SUPPORTED_LOCALES.includes(locale)) {
                router.replace({ query: { ...route.query, lang: locale } });
            }
        };
        const capitalize = (str: string) => str.substring(0, 1).toUpperCase() + str.substring(1);
        const currentLocale = computed(() => LOCALE_DATA[i18n.locale.value]);
        const locales = computed(() => SUPPORTED_LOCALES.map((locale) => LOCALE_DATA[locale]));

        return {
            currentLocale,
            locales,
            capitalize,
            select,
        };
    },
    mounted() {
        document.addEventListener("click", this.domEventTargetCheck, { capture: true });
        document.addEventListener("keydown", this.domKeyboardListener);
        document.addEventListener("focus", this.domEventTargetCheck, true);
    },
    beforeUnmount() {
        this.destroyPopper();
        document.removeEventListener("click", this.domEventTargetCheck);
        document.removeEventListener("keydown", this.domKeyboardListener);
        document.removeEventListener("focus", this.domEventTargetCheck);
    },
    methods: {
        domEventTargetCheck(ev: MouseEvent | FocusEvent) {
            if (!this.open) return;

            const container = this.$refs.container as HTMLDivElement;
            if (container && !container.contains(ev.target as Element)) {
                this.close();
            }
        },
        domKeyboardListener(ev: KeyboardEvent) {
            if (!this.open) return;

            if (ev.key === "Escape") {
                this.close();
            }
        },
        close() {
            this.open = false;
        },
        toggle() {
            this.open = !this.open;
        },
        createPopper() {
            if (this.popper) this.destroyPopper();

            const button = this.$refs.button as HTMLButtonElement;
            const menu = this.$refs.menu as HTMLDivElement;
            this.popper = createPopper(button, menu, {
                placement: "bottom",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            });
        },
        destroyPopper() {
            this.popper?.destroy();
            this.popper = null;
        },
        langFormat(lang: string) {
            return lang.substring(0, 2).toUpperCase();
        },
    },
    watch: {
        open(isOpen) {
            if (isOpen) {
                nextTick().then(() => {
                    this.createPopper();
                    const menu = this.$refs.menu as HTMLElement;
                    menu?.focus();
                });
            } else {
                this.destroyPopper();
            }
        },
    },
});
