import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-570d9666"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "popular-collections pt-6",
  "aria-labelledby": "popular-collections-heading"
}
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { class: "popular-collections-title pb-6 has-text-weight-medium has-text-white" }
const _hoisted_4 = { id: "popular-collections-heading" }
const _hoisted_5 = { class: "popular-collections-block" }
const _hoisted_6 = { class: "popular-collections-columns columns" }
const _hoisted_7 = { class: "py-2" }
const _hoisted_8 = { class: "pb-6 has-text-centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionBlock = _resolveComponent("CollectionBlock")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.results.length)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("collection.popular-collections")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (block) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "column is-half-tablet is-one-quarter-desktop",
                  key: block['@id']
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_CollectionBlock, { collection: block }, null, 8, ["collection"])
                  ])
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_router_link, {
              to: "/collections",
              class: "btn gossamer is-size-5 py-5 px-6"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("collection.popular-collections-show-more-link")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}