
import { defineComponent, PropType } from "vue";
import CollectionBlockLabel from "../../modules/collections/shared/CollectionBlockLabel.vue";
import CollectionBlockCollage from "../../modules/collections/shared/CollectionBlockCollage.vue";
import { UserCollectionDto } from "@kunsten/core";
import CollectionBlockOwner from "../../modules/collections/shared/CollectionBlockOwner.vue";

export default defineComponent({
    name: "CollectionBlock",
    components: { CollectionBlockLabel, CollectionBlockCollage, CollectionBlockOwner },
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
    },
});
