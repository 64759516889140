import { isEqual } from "lodash-es";
import { watch } from "vue";
import { useSearch, UseSearch, UseSearchOptions } from "./useSearch";

type MemoryCacheData = Pick<UseSearchOptions, "initialFilters" | "initialResults">;

const memoryCache = new Map<string, Readonly<MemoryCacheData>>();

function updateMemoryCache(key: string, data: Partial<MemoryCacheData>): void {
    const previous = memoryCache.get(key) || {};
    memoryCache.set(key, { ...previous, ...data });
}

/**
 * A wrapper around useSearch to provide a memory cache so the intermediate state can be persisted between components creations
 * and destructions as long as user did not refresh his browser window.
 *
 * This method just provides caching for the results, calling this hook twice on the same page with the same key won't ensure that it's data is synced,
 * but rather latest search result to be retrieved from the api would result in being saved in cache under given key.
 *
 * @param cacheKey A key to cache under - this can ensure persistance between component creations and deletions and also between different component.
 *                 Just keep the key unique if no other component should also have access to this cache.
 * @param options Options to pass to useSearch
 * @returns {UseSearch} output from calling useSearch for you
 */
export const useMemoryCachedSearch = (cacheKey: string, options: UseSearchOptions): UseSearch => {
    const { initialFilters: cachedFilters, initialResults: cachedResults } = memoryCache.get(cacheKey) || {};
    const initialFilters = options.initialFilters || cachedFilters;
    const shouldProvideCachedData = isEqual(initialFilters, cachedFilters);

    const searchOptions: UseSearchOptions = shouldProvideCachedData
        ? { ...options, initialResults: cachedResults, initialFilters: initialFilters }
        : { ...options };

    const search = useSearch(searchOptions);

    watch(
        () => [search.results.value, search.page.value, search.totalItems.value],
        () => {
            updateMemoryCache(cacheKey, {
                initialFilters: search.filters,
                initialResults: {
                    results: search.results.value,
                    totalItems: search.totalItems.value,
                    page: search.page.value,
                },
            });
        }
    );

    watch(search.filters, (filters) => {
        updateMemoryCache(cacheKey, { initialFilters: filters });
    });

    return search;
};

/**
 * Clears memory cache for search.
 */
export const purgeSearchMemoryCache = () => {
    memoryCache.clear();
};
