import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LandingPage from "./modules/landing-page/views/LandingPage.vue";
import JustRouterView from "./modules/general/JustRouterView.vue";
import NotFound from "./modules/general/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "LandingPage",
        component: LandingPage,
        meta: { unauthenticated: true },
    },
    {
        path: "/about-collections",
        name: "AboutCollections",
        component: () =>
            import(
                /* webpackChunkName: "about-collections" */ "./modules/about-collections/views/AboutCollections.vue"
            ),
        meta: { unauthenticated: true },
    },
    {
        path: "/search",
        name: "Search",
        component: () => import(/* webpackChunkName: "search" */ "./modules/search/views/Search.vue"),
        meta: { unauthenticated: true },
    },
    {
        path: "/my-collections",
        component: JustRouterView,
        children: [
            {
                path: "",
                name: "MyCollections",
                component: () =>
                    import(/* webpackChunkName: "my-collections" */ "./modules/collections/views/MyCollections.vue"),
                meta: { unauthenticated: true },
            },
            {
                path: ":id",
                name: "CollectionDetails",
                component: () =>
                    import(
                        /* webpackChunkName: "collection-details" */ "./modules/collections/views/CollectionDetails.vue"
                    ),
                meta: { unauthenticated: true },
            },
            {
                path: "actions",
                name: "CollectionActions",
                component: () =>
                    import(
                        /* webpackChunkName: "collection-actions" */ "./modules/collections/views/CollectionActions.vue"
                    ),
                meta: { unauthenticated: false },
            },
        ],
    },
    {
        path: "/collections",
        name: "Collections",
        component: () => import(/* webpackChunkName: "collections" */ "./modules/collections/views/Collections.vue"),
        meta: { unauthenticated: true },
    },
    {
        path: "/exhibitions",
        component: JustRouterView,
        children: [
            {
                path: "",
                name: "Exhibitions",
                component: () =>
                    import(/* webpackChunkName: "exhibitions" */ "./modules/exhibitions/views/Exhibitions.vue"),
            },
            {
                path: ":id",
                name: "ExhibitionDetails",
                component: () =>
                    import(
                        /* webpackChunkName: "exhibition-details" */ "./modules/exhibitions/views/ExhibitionDetails.vue"
                    ),
            },
        ],
        meta: { unauthenticated: true },
    },
    {
        path: "/periods",
        component: JustRouterView,
        children: [
            {
                path: ":id",
                name: "PeriodDetails",
                component: () =>
                    import(/* webpackChunkName: "period-details" */ "./modules/periods/views/PeriodDetails.vue"),
            },
        ],
        meta: { unauthenticated: true },
    },
    {
        path: "/artists",
        component: JustRouterView,
        children: [
            {
                path: "",
                name: "Artists",
                component: () => import(/* webpackChunkName: "artists" */ "./modules/artists/views/Artists.vue"),
            },
        ],
        meta: { unauthenticated: true },
    },
    {
        path: "/themes",
        component: JustRouterView,
        children: [
            {
                path: "",
                name: "Themes",
                component: () => import(/* webpackChunkName: "themes" */ "./modules/themes/views/Themes.vue"),
            },
            {
                path: ":id",
                name: "ThemeDetails",
                component: () =>
                    import(/* webpackChunkName: "theme-details" */ "./modules/themes/views/ThemeDetails.vue"),
            },
        ],
        meta: { unauthenticated: true },
    },
    {
        path: "/artworks",
        component: JustRouterView,
        children: [
            {
                path: ":id",
                name: "ArtworkPage",
                component: () =>
                    import(/* webpackChunkName: "artwork-page" */ "./modules/artwork/views/ArtworkPage.vue"),
            },
        ],
        meta: { unauthenticated: true },
    },
    {
        path: "/acquisitions",
        component: JustRouterView,
        children: [
            {
                path: "",
                name: "Acquisitions",
                component: () =>
                    import(/* webpackChunkName: "acquisitions" */ "./modules/acquisitions/views/Acquisitions.vue"),
            },
            {
                path: ":id",
                name: "AcquisitionDetails",
                component: () =>
                    import(
                        /* webpackChunkName: "acquisition-details" */ "./modules/acquisitions/views/AcquisitionDetails.vue"
                    ),
            },
        ],
        meta: { unauthenticated: true },
    },
    {
        path: "/login",
        component: JustRouterView,
        children: [
            {
                path: "google",
                name: "LoginGoogle",
                component: () => import(/* webpackChunkName: "login-google" */ "./modules/login/views/LoginGoogle.vue"),
            },
            {
                path: "facebook",
                name: "LoginFacebook",
                component: () =>
                    import(/* webpackChunkName: "login-facebook" */ "./modules/login/views/LoginFacebook.vue"),
            },
        ],
        meta: { unauthenticated: true },
    },
    { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound, meta: { unauthenticated: true } },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        // prevent scroll on search page
        // during pagination and changing filters
        if (from.name === "Search" && to.name === "Search") {
            return;
        }
        // if hash link => /page#section
        // scroll smoothly to desired position
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: "smooth",
            };
        }
        // else if redirect to Search scroll to savedPosition or top
        else if (to.name === "Search") {
            if (savedPosition) savedPosition.behavior = "smooth";
            return savedPosition ?? { left: 0, top: 0 };
        }
        // else just scroll to top
        else {
            return { left: 0, top: 0 };
        }
    },
});

export default router;
