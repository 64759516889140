
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'ArtWorkSearch',
    setup() {
        const route = useRoute();

        const showSearchInput = computed(() => {
            const hideSearchInputRoutes = ['/', '/search'];
            return !hideSearchInputRoutes.includes(route.path);
        });

        return { showSearchInput };
    },
});
