import { ArtistDto } from "../dto/artist";
import { useAPI } from "../api";
import { useApiResource } from "../api-resource";
import axios, { AxiosResponse } from "axios";
import { HydraCollection } from "../dto/hydra";

export interface ArtistQueryParams {
    page: string;
}

export function useArtists() {
    const { http } = useAPI();

    const getArtists = (params: ArtistQueryParams): Promise<AxiosResponse<HydraCollection<ArtistDto>>> => {
        return http.get<HydraCollection<ArtistDto>>(`/api/artists`, { params });
    };

    const getArtist = (id: string): Promise<AxiosResponse<ArtistDto>> => {
        return http.get<ArtistDto>(`/api/artists/${id}`);
    };

    const getMultipleArtists = (artists: string[]) => {
        return axios.all(
            artists.map((iri) => {
                return http.get<ArtistDto>(iri);
            })
        );
    };

    return {
        getArtists,
        getArtist,
        getMultipleArtists,
    };
}

export function useArtistsResource(params: ArtistQueryParams) {
    return useApiResource<ArtistDto, ArtistQueryParams>(`/api/artists`, params);
}
