import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-882614d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      class: "kunsten-skeleton-image",
      onLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded = true)),
      onError: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loaded = true)),
      src: _ctx.imgSrc,
      "data-testid-img": "",
      alt: ""
    }, null, 40, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(["kunsten-skeleton-loader", { loaded: _ctx.loaded }]),
      "data-testid-skeleton": ""
    }, null, 2)
  ], 64))
}