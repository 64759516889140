export function useColumnView<T>() {
    const getResultsByColumn = (column: number, columns: number, results: Array<T>) => {
        const length = results?.length || 0;
        if (!length) return [];
        const rows = Math.ceil(length / columns);
        const indexes = [];
        for (let i = 0; i < rows; i++) {
            const index = i * columns + column;
            if (index < length) {
                indexes.push(index);
            }
        }
        // We checked before if every index is within array range
        // eslint-disable-next-line
        // @ts-ignore
        return indexes.map((i) => results[i]);
    };

    const getGroupedResults = (columns: number, results: Array<T>): Array<Array<T>> => {
        const groupedResults = [];
        for (let i = 0; i < columns; i++) {
            groupedResults.push(getResultsByColumn(i, columns, results));
        }
        return groupedResults;
    };

    const getUngroupedResults = (columns: number, groupedResults: Array<Array<T>>): Array<T> => {
        const results: Array<Array<T>> = JSON.parse(JSON.stringify(groupedResults));
        let flatten = [] as T[];
        const length = results.reduce((sum, r) => sum + r.length, 0);
        if (!length) return [];
        const rows = Math.ceil(length / columns);
        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < columns; j++) {
                flatten = [...flatten, ...results[j].splice(0, 1)];
            }
        }
        return flatten;
    };

    const removeElement = (column: number, index: number, groupedResults: Array<Array<T>>) => {
        groupedResults[column].splice(index, 1);
        return groupedResults;
    };

    const addElement = (payload: T, column: number, index: number, groupedResults: Array<Array<T>>) => {
        groupedResults[column].splice(index, 0, payload);
        return groupedResults;
    };

    // columns length must be descending
    const isCorrect = (columns: number, groupedResults: Array<Array<T>>): boolean => {
        let prevLength = 0;
        for (let i = 0; i < columns; i++) {
            if (groupedResults[i] === undefined) return false;
            const currLength = groupedResults[i].length;
            if (i > 0 && currLength > prevLength) {
                return false;
            }
            prevLength = currLength;
        }
        return true;
    };

    return { getGroupedResults, getUngroupedResults, removeElement, addElement, isCorrect };
}
