import { TokenDataDto } from "../dto/token";

export function parseToken(token: string): TokenDataDto {
    const parts = token.split(".");
    if (parts.length !== 3) throw new Error("Invalid JWT token (not enough parts)");

    try {
        return JSON.parse(atob(parts[1]));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
        throw new Error("Failed to parse token as JSON: " + e.message);
    }
}
