
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import PresavedSearches from "../../../../shared/components/PresavedSearches.vue";

export default defineComponent({
    name: "HomeSearch",
    components: { PresavedSearches },
    setup() {
        const { locale } = useI18n();
        const router = useRouter();

        const navigateToSearch = () => {
            router.push({ path: "/search", query: { lang: locale.value, page: "1" } });
        };

        const navigateToSearchWithQuery = (value: string) => {
            router.push({ path: "/search", query: { lang: locale.value, page: "1", q: value } });
        };

        return { navigateToSearch, navigateToSearchWithQuery };
    },
});
