import { useAPI } from "../api";
import { AxiosResponse } from "axios";

export function useRegister() {
    const { http } = useAPI();

    function register(email: string, password: string): Promise<AxiosResponse<void>> {
        return http.post<void>(`/api/users/register`, { email, password }).then((res) => {
            return res;
        });
    }

    return {
        register,
    };
}
