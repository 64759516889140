import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e5f711e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "kunsten-skip-navigation",
  href: "#maincontent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", _hoisted_1, _toDisplayString(_ctx.$t("general.skip-to-main-content")), 1))
}