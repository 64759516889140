<template>
    <div class="kunsten-input" :class="{ 'kunsten-input--light': light }">
        <label data-testid-label v-if="modelValue" class="kunsten-input-label">{{ label }}</label>
        <input
            data-testid-input
            :type="type"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :placeholder="label"
            :class="{ invalid: invalid }"
            @focus="$emit('keyboard')"
            @blur="$emit('keyboard')"
        />
        <Alert v-if="invalid" :message="errorMessage" />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import Alert from "./Alert.vue";

export default defineComponent({
    name: "Input",
    components: { Alert },
    emits: ["update:modelValue", "keyboard"],
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "text",
        },
        label: {
            type: String,
            required: true,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        errorMessage: String,
    },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
@import "@/styles/mixins.scss";

.kunsten-input {
    position: relative;
    input {
        border: solid 2px $kun-dark-alt;
        padding: 0.875rem 1.125rem;
        font-size: 1.125rem;
        background-color: $kun-white;
        width: 100%;
        position: relative;
        outline: none;
        &.invalid {
            border: solid 2px $kun-error;
        }
        &:focus-visible {
            border: solid 2px $kun-silver;
            & + .alert {
                display: none;
            }
        }
    }
    &-label {
        position: absolute;
        top: -0.625rem;
        left: 0.5rem;
        color: rgba($kun-dark-alt, 0.46);
        font-size: 1rem;
        font-weight: 300;
        padding: 0 0.5rem;
        background-color: $kun-white;
        z-index: 10;
    }
    &--light {
        input {
            color: $kun-white;
            border: solid 2px $kun-white;
            background-color: $kun-dark;
            &.invalid {
                border: solid 2px $kun-error;
            }
            &:focus-visible {
                border: solid 2px $kun-silver;
            }
        }
        .kunsten-input-label {
            color: rgba($kun-white-alt, 0.46);
            background-color: $kun-dark;
        }
    }
}
</style>
