
import { defineComponent, ref } from "vue";
import Modal from "../../../shared/components/Modal.vue";
import Input from "../../../shared/components/Input.vue";
// import Checkbox from "../../../shared/components/Checkbox.vue";
import Alert from "../../../shared/components/Alert.vue";
import Spinner from "../../../shared/components/Spinner.vue";
import { SocialMediaType, useLogin, useMobileKeyboard } from "@kunsten/core";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
    name: "LoginPopup",
    components: { Modal, Input, /*Checkbox,*/ Alert, Spinner },
    data() {
        return {
            email: "",
            password: "",
            remember: false,
        };
    },
    validations() {
        return {
            email: { required, email },
            password: { required },
        };
    },
    setup() {
        const v$ = useVuelidate();
        const router = useRouter();
        const route = useRoute();
        const backendError = ref("");
        const loading = ref(false);
        const { login, startSocialMediaLogin } = useLogin();
        const { toggleModalKeyboardClass } = useMobileKeyboard();

        const socialMediaLogin = (socialMedia: SocialMediaType) => {
            window.localStorage.setItem("kunsten_login_from", "portal");
            startSocialMediaLogin(socialMedia);
        };

        const getQueryParamsFromString = (text: string) => {
            let query = {} as { [key: string]: string };
            if (text) {
                const paramsArr = text.split("&");
                const paramsPairs: { param: string; value: string }[] = paramsArr.map((p) => {
                    return {
                        param: p.split("=")[0],
                        value: p.split("=")[1],
                    };
                });
                paramsPairs.forEach((p) => {
                    query[p.param] = p.value;
                });
                return query;
            }
            return query;
        };

        const submit = (email: string, password: string): void => {
            backendError.value = "";
            loading.value = true;
            login(email, password)
                .then(() => {
                    const redirect = route.query.redirect;
                    if (redirect && typeof redirect === "string") {
                        const parts = redirect.split("?");
                        router.push({ path: parts[0], query: getQueryParamsFromString(parts[1]) });
                    } else {
                        router.push({ name: "MyCollections" });
                    }
                    loading.value = false;
                })
                .catch((err) => {
                    if (err.response) {
                        backendError.value = err.response.data.message;
                    } else {
                        backendError.value = err.message;
                    }
                    loading.value = false;
                });
        };

        return {
            v$,
            submit,
            socialMediaLogin,
            toggleModalKeyboardClass,
            backendError,
            loading,
        };
    },
});
