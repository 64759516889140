
import { usePresavedSearchesResource } from "@kunsten/core";
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Block from "./Block.vue";

export default defineComponent({
    name: "PresavedSearches",
    components: { Block },
    props: {
        light: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { locale } = useI18n();
        const { entities } = usePresavedSearchesResource({
            page: "1",
        });

        const labelProp = computed(() => {
            return `label${locale.value.charAt(0).toUpperCase()}${locale.value.slice(1)}Translation`;
        });

        return { entities, labelProp };
    },
});
