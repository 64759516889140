import { PeriodDto, TranslationPeriodDto } from "../dto/period";
import { useAPI } from "../api";
import { useApiResource } from "../api-resource";
import axios, { AxiosResponse } from "axios";
import { HydraCollection } from "../dto/hydra";
import { Resource, ResourceData, Whole } from "../util/types";

export interface PatchPeriodData {
    id: string;
    enTranslation?: TranslationPeriodDto;
    daTranslation?: TranslationPeriodDto;
    artworks?: string[];
}

export interface PeriodQueryParams {
    page: string;
    pagination?: string;
    "daTranslation.title"?: string;
    "enTranslation.title"?: string;
    "order[daTranslation.title]"?: string;
    "order[enTranslation.title]"?: string;
    "order[sortNumber]"?: string;
    "artworks.id"?: string;
}

export function usePeriods() {
    const { http } = useAPI();

    const getPeriods = (params: PeriodQueryParams): Promise<AxiosResponse<HydraCollection<PeriodDto>>> => {
        return http.get<HydraCollection<PeriodDto>>(`/api/periods`, { params });
    };

    const getPeriod = (id: string): Promise<AxiosResponse<PeriodDto>> => {
        return http.get<PeriodDto>(`/api/periods/${id}`);
    };

    const createPeriod = (data: ResourceData<PeriodDto>): Promise<AxiosResponse<Resource>> => {
        return http.post<PeriodDto>(`/api/periods`, data);
    };

    const patchPeriod = (id: string, data: PatchPeriodData): Promise<AxiosResponse<Whole<PatchPeriodData>>> => {
        return http.patch<PeriodDto>(`/api/periods/${id}`, data);
    };

    const patchMultiplePeriods = (periods: PatchPeriodData[]) => {
        return axios.all(
            periods.map((period) => {
                return patchPeriod(period.id, period);
            })
        );
    };

    const deletePeriod = (id: string): Promise<AxiosResponse<void>> => {
        return http.delete(`/api/periods/${id}`);
    };

    return {
        getPeriods,
        getPeriod,
        createPeriod,
        patchPeriod,
        patchMultiplePeriods,
        deletePeriod,
    };
}

export function usePeriodsResource(params: PeriodQueryParams) {
    return useApiResource<PeriodDto, PeriodQueryParams>(`/api/periods`, params);
}
