
import { computed, defineComponent } from "vue";
import { useAPI } from "../api";

export default defineComponent({
    name: "IsOwnedByUser",
    props: {
        ownerIri: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { userData } = useAPI();

        const userIri = computed(() => {
            return "/api/users/" + userData.value?.id;
        });

        const isOwnedByUser = computed(() => {
            return userIri.value === props.ownerIri;
        });

        return { isOwnedByUser };
    },
});
