
import { defineComponent, PropType } from "vue";

type LabelSize = "sm" | "md" | "bg" | "lg";

export default defineComponent({
    name: "Label",
    props: {
        size: {
            type: String as PropType<LabelSize>,
            default: "md",
        },
        subSize: {
            type: String as PropType<LabelSize>,
            default: "md",
        },
    },
});
