import { createI18n } from "vue-i18n";
import enLocaleData from "./locales/en.json";
import core from "./core";

export const SUPPORTED_LOCALES = ["da", "en"];

export interface LocaleData {
    locale: string;
    name: string;
    flag: string;
}

export const LOCALE_DATA: Record<string, LocaleData> = {
    da: { locale: "da", name: "Dansk", flag: "da.png" },
    en: { locale: "en", name: "English", flag: "en.png" },
};

const i18n = createI18n({
    locale: "en",
    fallbackLocale: "en",
    globalInjection: true,
    messages: {
        en: enLocaleData,
    },
});

const locale = window.localStorage.getItem("kunsten_locale") || getUsersLocale();
export const localeReady = changeLocale(locale);

export async function changeLocale(locale: string): Promise<void> {
    if (!SUPPORTED_LOCALES.includes(locale)) {
        return Promise.reject(
            new Error(`Unsupported locale ${locale}! Supported locales are ${SUPPORTED_LOCALES.join(", ")}.`)
        );
    }

    i18n.global.locale = locale;
    document.documentElement.setAttribute("lang", locale);
    window.localStorage.setItem("kunsten_locale", locale);
    core?.api.setApiLocale(locale);
    if (locale === "da") {
        document.title = "Kunsten Collection - digital samlingsdatabase";
        document
            .querySelector('meta[name="description"]')
            ?.setAttribute(
                "content",
                "Gå på opdagelse i tusindvis af kunstværker i Kunstens samling - Kunsten Museum of Modern Art Aalborg"
            );
    } else {
        document.title = "Kunsten Collection - digital collection database";
        document
            .querySelector('meta[name="description"]')
            ?.setAttribute(
                "content",
                "Explore thousands of artworks from Kunsten's collection - Kunsten Museum of Modern Art Aalborg"
            );
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(i18n.global.messages as any)[locale]) {
        const { default: localeData } = await import(
            /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
        );
        i18n.global.setLocaleMessage(locale, localeData);
    }
}

function getUsersLocale() {
    if (window.navigator.languages) {
        return (
            window.navigator.languages
                .map((language) => language.substring(0, 2))
                .find((language) => SUPPORTED_LOCALES.includes(language)) || "en"
        );
    } else {
        const nav = window.navigator as unknown as Record<string, string>;
        const usersLocale =
            window.navigator.language || nav.userLanguage || nav.browserLanguage || nav.systemLanguage || "en";
        const locale = usersLocale.substring(0, 2);
        return SUPPORTED_LOCALES.includes(locale) ? locale : "en";
    }
}

export default i18n;
