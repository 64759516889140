
import { computed, defineComponent, ref } from "vue";
import Modal from "../../../shared/components/Modal.vue";
import Input from "../../../shared/components/Input.vue";
import Alert from "../../../shared/components/Alert.vue";
import Spinner from "../../../shared/components/Spinner.vue";
import useVuelidate from "@vuelidate/core";
import { useCreatePassword } from "@kunsten/core";
import { required, minLength } from "@vuelidate/validators";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
    name: "CreatePasswordPopup",
    components: { Modal, Input, Alert, Spinner },
    data() {
        return {
            password: "",
            confirmPassword: "",
        };
    },
    validations() {
        return {
            password: { required, minLength: minLength(8) },
            confirmPassword: {
                required,
                sameAs: this.repeatPassword,
            },
        };
    },
    methods: {
        repeatPassword(password: string) {
            return password === this.password;
        },
    },
    setup() {
        const v$ = useVuelidate();
        const route = useRoute();
        const token = computed(() => {
            return (route.query.token as string) || "";
        });
        const router = useRouter();
        const backendError = ref("");
        const loading = ref(false);
        const { createPassword } = useCreatePassword();

        const submit = (password: string): void => {
            backendError.value = "";
            loading.value = true;
            createPassword(password, token.value)
                .then(() => {
                    router.push({ name: "LandingPage", query: { view: "login" } });
                    loading.value = false;
                })
                .catch((err) => {
                    if (err.response) {
                        backendError.value = err.response.data.message;
                    } else {
                        backendError.value = err.message;
                    }
                    loading.value = false;
                });
        };

        return {
            v$,
            submit,
            backendError,
            loading,
        };
    },
});
