/* eslint-disable @typescript-eslint/no-explicit-any */
import { reactive, readonly, Ref, ref, watch, computed, ComputedRef, DeepReadonly } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { ALL_FILTER_KEYS, FilterOptions, FiltersData, SELECTS_FILTER_KEYS } from "./filters";
import { useHttp } from "../api";
import { EsArtworkDto } from "../dto/es-artwork";

export const SEARCH_API_URL = "/api/es_artworks";

const DEFAULT_ITEMS_PER_PAGE = 28; // chosen number is divisible by 4 so we can display full rows when rendering results
export const INITIAL_FILTER_OPTIONS: FilterOptions = {
    artType: { terms: [] },
    creatorsGenders: { terms: [] },
    creatorsNames: { terms: [] },
    creatorsNationalities: { terms: [] },
    materials: { terms: [] },
    technique: { terms: [] },
    onDisplay: { terms: [] },
    productionStartDate: {
        min: null,
        max: null,
    },
    productionEndDate: {
        min: null,
        max: null,
    },
};

export type UseSearchResults = DeepReadonly<EsArtworkDto[]>;
export type UseSearchFilterOptions = DeepReadonly<FilterOptions>;

export type SearchQueryReturnValue = Promise<AxiosResponse<EsArtworksApiResponse>>;

export interface UseSearch {
    /**
     * Filters data that is sent to api
     */
    filters: FiltersData;
    /**
     * Whether there is an api call ongoing
     */
    loading: Ref<boolean>;
    /**
     * Whether there is an api call ongoing for filters
     */
    totalItems: Ref<number>;
    /**
     * Filter options returned from api, before the query is made this object will have empty lists.
     */
    results: Ref<UseSearchResults>;
    /**
     * Error, if query to api finished with an error, otherwise null. Can be used to display information to user that something went wrong.
     */
    error: Ref<any | null>;
    /**
     * Current page that was loaded from the api.
     */
    page: Ref<number>;
    /**
     * Whether user can load more options. It will check if the current request is in progress and if there are any more elements to load for
     * the current serach query.
     */
    canLoadMore: ComputedRef<boolean>;
    /**
     * Method that executes search, can be called to refresh data from the api
     */
    search: () => SearchQueryReturnValue;
    /**
     * Loads more data from the api (next page) and appends to results list
     */
    loadMore: () => SearchQueryReturnValue;
}

export interface UseSearchInitialResults {
    page: number;
    results: UseSearchResults;
    totalItems: number;
}

export type UseSearchInitialFilters = Omit<FiltersData, "lang">;

export interface UseSearchOptions {
    /**
     * Language that is used to make requests, since we might need to use a different language in some places useSearch is not tied to global language
     * instead it accepts a ref to the language, when updated it will make request again
     */
    language: Ref<string>;
    /**
     * Items displayed per page.
     *
     * This option is passed to the api and is not dynamic.
     */
    itemsPerPage?: number;
    /**
     * Initial response data that useSearch should use instead of making call to api.
     * If you want to set initial filters then @see {UseSearchOptions.initialFilters}
     *
     * If initial data is set but some persistance option is selected then this would be overriden.
     * See more in @see {UseSearchOptions.persistance}
     */
    initialResults?: UseSearchInitialResults;
    /**
     * Initial filters data that will be used for initial request, initial request is not performed if initial data is set.
     * @see {UseSearchOptions.initialResults}
     */
    initialFilters?: Readonly<UseSearchInitialFilters>;
}

export interface EsArtworksApiResponse {
    "hydra:totalItems": number;
    "hydra:member": EsArtworkDto[];
    "hydra:es:aggregation": FilterOptions;
}

export function useSearch({
    language,
    initialResults,
    initialFilters,
    itemsPerPage: optionItemsPerPage,
}: UseSearchOptions): UseSearch {
    const http = useHttp();

    const filters = reactive<FiltersData>({
        lang: language.value || "en",
        ...(initialFilters || {}),
    });

    const itemsPerPage: number = optionItemsPerPage || DEFAULT_ITEMS_PER_PAGE;
    const page = ref<number>(initialResults?.page || 1);
    const loading = ref<boolean>(false);
    const results = ref<UseSearchResults>(initialResults?.results || []);
    const totalItems = ref<number>(initialResults?.totalItems || -1);
    const error = ref<any | null>(null);
    const canLoadMore = computed(() => {
        return !loading.value && page.value * itemsPerPage < totalItems.value;
    });

    let cancelToken: CancelTokenSource | undefined;
    const query = (): SearchQueryReturnValue => {
        if (cancelToken) cancelToken.cancel();
        cancelToken = axios.CancelToken.source();

        loading.value = true;

        const params = {
            ...filters,
            page: page.value,
            itemsPerPage,
        };

        return http
            .get<EsArtworksApiResponse>(SEARCH_API_URL, { params, cancelToken: cancelToken.token })
            .then((res) => {
                loading.value = false;
                cancelToken = undefined;
                return res;
            })
            .catch((e: Error) => {
                cancelToken = undefined;

                if (!axios.isCancel(e)) {
                    loading.value = false;
                    error.value = e;
                }

                throw e;
            });
    };

    const search = (): SearchQueryReturnValue => {
        return query().then((res) => {
            results.value = res.data["hydra:member"];
            totalItems.value = res.data["hydra:totalItems"];
            return res;
        });
    };

    const loadMore = (): SearchQueryReturnValue => {
        if (loading.value) return Promise.reject(new Error("Cannot load more when query is in progress!"));

        page.value = page.value + 1;
        return query().then((res) => {
            results.value = [...results.value, ...res.data["hydra:member"]];
            return res;
        });
    };

    // start searching when language is changed or filters change
    watch(
        () => [language.value, filters],
        () => {
            page.value = 1;
            search();
        },
        { deep: true }
    );

    if (!initialResults) {
        search().catch((err) => {
            if (axios.isCancel(err)) {
                return;
            }

            // Initial error failed, log it, error.value will have the error
            console.error("Initial search request failed with", err);
            throw err;
        });
    }

    return {
        filters,
        loading: readonly(loading),
        results: readonly(results),
        totalItems: readonly(totalItems),
        error: readonly(error),
        page: readonly(page),
        search,
        loadMore,
        canLoadMore,
    };
}

/**
 * Given query object parses it as filters object and returns filters which can be used as initialFilters in useSearch
 *
 * @param query {Record<string, string | string[]>} Query object
 * @returns {UseSearchInitialFilters} Initial filters object parsed from query object. Will contain only known keys to search and they
 * will have a correct type.
 */
export function filtersFromQuery(query: Record<string, string | string[]>): UseSearchInitialFilters {
    const filters: UseSearchInitialFilters = {};

    Object.keys(query)
        .filter((key) => ALL_FILTER_KEYS.includes(key))
        .forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const value = query[key];

            if ((SELECTS_FILTER_KEYS as string[]).includes(key)) {
                filters[key] = Array.isArray(value) ? value : [value];
            } else if (value === "true") {
                filters[key] = true;
            } else if (value === "false") {
                filters[key] = false;
            } else {
                filters[key] = value;
            }
        });

    return filters;
}

/**
 * Given reactive filters object and page syncs it's value with route query
 * @param filters {FiltersData} - Reactive filters object
 */
export function syncFiltersAndPageWithQuery(filters: Readonly<FiltersData>, page: Ref<number>): void {
    const router = useRouter();
    const route = useRoute();

    watch(
        [filters, page],
        ([filters, page]) => {
            const normalizedFilters = Object.keys(filters).reduce((acc, key) => {
                if (typeof filters[key] === "boolean") {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    acc[key] = filters[key]!.toString();
                } else {
                    acc[key] = filters[key] as string | string[];
                }
                return acc;
            }, {} as Record<string, string | string[]>);

            const previousQuery = { ...route.query };

            Object.keys(previousQuery)
                .filter((key) => ALL_FILTER_KEYS.includes(key))
                .forEach((key) => {
                    delete previousQuery[key];
                });

            router.replace({ query: { ...previousQuery, ...normalizedFilters, page: page } });
        },
        { deep: true }
    );
}
