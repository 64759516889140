
import { defineComponent, PropType } from "vue";
import IconText from "../../../shared/components/IconText.vue";
import { UserCollectionDto, IsOwnedByUser } from "@kunsten/core";

export default defineComponent({
    name: "CollectionBlockOwner",
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
        darkIcon: {
            type: Boolean,
            default: false,
        },
    },
    components: { IconText, IsOwnedByUser },
});
