import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleYearLabel = _resolveComponent("TitleYearLabel")!
  const _component_ArtworkCard = _resolveComponent("ArtworkCard")!
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createBlock(_component_Slider, { entities: _ctx.entities }, {
    default: _withCtx(({ entity }) => [
      _createVNode(_component_ArtworkCard, {
        imgSrc: entity.reproductions.length ? entity.reproductions[0] : null,
        artworkId: entity['id']
      }, {
        title: _withCtx(() => [
          _createVNode(_component_TitleYearLabel, { artwork: entity }, null, 8, ["artwork"])
        ]),
        creators: _withCtx(() => [
          _createTextVNode(_toDisplayString(entity.creatorsNames.join(", ")), 1)
        ]),
        _: 2
      }, 1032, ["imgSrc", "artworkId"])
    ]),
    _: 1
  }, 8, ["entities"]))
}