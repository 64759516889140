export function useTextLimit(text: string, limit = 0) {
    if (limit === 0 || !text) return text;

    const words = text.split(" ");
    let charCount = 0;
    let str = "";

    for (const word of words) {
        charCount += word.length + 1; // +1 for every space added
        if (charCount >= limit) {
            return str.trim() + "…"; // add ellipsis if limit exceeded & return
        }
        str += word + " ";
    }

    return str;
}
