
import { defineComponent } from "vue";
import HomeSearch from "./HomeSearch.vue";

export default defineComponent({
    name: "HomeSection",
    components: {
        HomeSearch,
    },
});
