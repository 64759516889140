import { ExhibitionDto, TranslationExhibitionDto } from "../dto/exhibition";
import { useAPI } from "../api";
import { useApiResource } from "../api-resource";
import { AxiosResponse } from "axios";
import { HydraCollection } from "../dto/hydra";
import { Whole } from "../util/types";

export interface PatchExhibitionData {
    enTranslation?: TranslationExhibitionDto;
    daTranslation?: TranslationExhibitionDto;
}

export interface ExhibitionQueryParams {
    page: string;
    pagination?: string;
    "order[startDate]": string;
    "artworks.id"?: string;
}

export function useExhibitions() {
    const { http } = useAPI();

    const getExhibitions = (params: ExhibitionQueryParams): Promise<AxiosResponse<HydraCollection<ExhibitionDto>>> => {
        return http.get<HydraCollection<ExhibitionDto>>(`/api/exhibitions`, { params });
    };

    const getExhibition = (id: string): Promise<AxiosResponse<ExhibitionDto>> => {
        return http.get<ExhibitionDto>(`/api/exhibitions/${id}`);
    };

    const patchExhibition = (id: string, data: PatchExhibitionData): Promise<AxiosResponse<Whole<ExhibitionDto>>> => {
        return http.patch<ExhibitionDto>(`/api/exhibitions/${id}`, data);
    };

    return {
        getExhibitions,
        getExhibition,
        patchExhibition,
    };
}

export function useExhibitionsResource(params: ExhibitionQueryParams) {
    return useApiResource<ExhibitionDto, ExhibitionQueryParams>(`/api/exhibitions`, params);
}
