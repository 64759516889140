
import { defineComponent } from "vue";

export default defineComponent({
    name: "ClickedOutside",
    props: {
        is: {
            type: String,
            default: () => "div",
        },
    },
    created() {
        this.listener = this.listener.bind(this);
    },
    mounted() {
        document.body.addEventListener("click", this.listener);
    },
    beforeUnmount() {
        document.body.removeEventListener("click", this.listener);
    },
    methods: {
        listener(e: MouseEvent): void {
            if (!(this.$refs.el as HTMLElement).contains(e.target as Node)) {
                this.$emit("clicked", e);
            }
        },
    },
});
