
import { defineComponent } from "vue";
import { ThemeDto, useThemesResource } from "@kunsten/core";
import getEntityTranslation from "../../../entity/get-entity-translation";
import EntityHero from "../../../entity/EntityHero.vue";
import BigCard from "../../../../shared/components/BigCard.vue";

export default defineComponent({
    name: "ThemesSection",
    components: { EntityHero, BigCard },
    setup() {
        const resource = useThemesResource({
            page: "1",
            itemsPerPage: "11", // 10 + first one displayed in hero section
            "order[sortNumber]": "asc",
            promoteToFront: "true",
        });

        return { ...resource };
    },
    methods: {
        getTranslation(data: ThemeDto, property: string) {
            return getEntityTranslation<ThemeDto>(data, property);
        },
    },
});
