import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96ff1944"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-block-collage" }
const _hoisted_2 = { class: "collection-block-collage-left" }
const _hoisted_3 = {
  class: "image-wrapper",
  style: {"padding-right":"0"}
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "collection-block-collage-right" }
const _hoisted_6 = { class: "image-wrapper aside-image" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "image-wrapper aside-image" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlankImage = _resolveComponent("BlankImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("figure", null, [
          (_ctx.images[0])
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.images[0] + '/download?width=300&height=300',
                alt: ""
              }, null, 8, _hoisted_4))
            : (_openBlock(), _createBlock(_component_BlankImage, {
                key: 1,
                size: "md"
              }))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("figure", null, [
          (_ctx.images[1])
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.images[1] + '/download?width=150&height=150',
                alt: ""
              }, null, 8, _hoisted_7))
            : (_openBlock(), _createBlock(_component_BlankImage, {
                key: 1,
                size: "sm"
              }))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("figure", null, [
          (_ctx.images[2])
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.images[2] + '/download?width=150&height=150',
                alt: ""
              }, null, 8, _hoisted_9))
            : (_openBlock(), _createBlock(_component_BlankImage, {
                key: 1,
                size: "sm"
              }))
        ])
      ])
    ])
  ]))
}