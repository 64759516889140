
/* Alternative version of translation component mostly for headings purposes */
import { defineComponent } from "vue";
import getEntityTranslation from "../../modules/entity/get-entity-translation";

export default defineComponent({
    name: "Translation",
    props: {
        data: {
            type: Object,
            required: true,
        },
        property: {
            type: String,
            required: true,
        },
    },
    computed: {
        translation() {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return getEntityTranslation<any>(this.data, this.property);
        },
    },
});
