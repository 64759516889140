
import { defineComponent } from "vue";

export default defineComponent({
    name: "Modal",
    emits: ["close"],
    mounted() {
        (this.$refs.modal as HTMLDivElement).focus();
        document.body.classList.add("modal-open");
    },
    beforeUnmount() {
        document.body.classList.remove("modal-open");
    },
});
