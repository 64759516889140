import { ArtworkDto } from "../dto/artwork";
import { useAPI } from "../api";
import { useApiResource } from "../api-resource";
import axios, { AxiosResponse } from "axios";
import { HydraCollection } from "../dto/hydra";
import { EsArtworkDto } from "../dto/es-artwork";

export interface ArtworkQueryParams {
    page: string;
}

export function useArtworks() {
    const { http } = useAPI();

    const getArtworks = (params: ArtworkQueryParams): Promise<AxiosResponse<HydraCollection<ArtworkDto>>> => {
        return http.get<HydraCollection<ArtworkDto>>(`/api/artworks`, { params });
    };

    const getArtwork = (id: string): Promise<AxiosResponse<ArtworkDto>> => {
        return http.get<ArtworkDto>(`/api/artworks/${id}`);
    };

    const getEsArtwork = (id: string, params: { lang: string }): Promise<AxiosResponse<EsArtworkDto>> => {
        return http.get<EsArtworkDto>(`/api/es_artworks/${id}`, { params });
    };

    const getEsArtworks = (params: {
        lang: string;
        id: string[];
    }): Promise<AxiosResponse<HydraCollection<EsArtworkDto>>> => {
        return http.get<HydraCollection<EsArtworkDto>>(`/api/es_artworks`, { params });
    };

    const getMultipleEsArtworks = (artworks: string[], lang: string) => {
        return axios.all(
            artworks.map((artwork) => {
                return getEsArtwork(artwork, { lang });
            })
        );
    };

    return {
        getArtworks,
        getArtwork,
        getEsArtwork,
        getEsArtworks,
        getMultipleEsArtworks,
    };
}

export function useArtworksResource(params: ArtworkQueryParams) {
    return useApiResource<ArtworkDto, ArtworkQueryParams>(`/api/artworks`, params);
}
