import { computed, ComputedRef, ref } from "vue";
import jwt_decode from "jwt-decode";

export interface TokenCredentials {
    roles: string[];
    email: string;
    userIri: string;
}

export function useTokenCredentials() {
    const token = ref(window.localStorage.getItem("kunsten_token"));

    const getUuidFromIri = (iri: string): string => {
        return iri.split("/")[3];
    };

    const decoded: ComputedRef<TokenCredentials | null> = computed(() => {
        return token.value ? (jwt_decode(token.value) as TokenCredentials) : null;
    });

    const userIri: ComputedRef<string | null> = computed(() => {
        return decoded.value ? decoded.value.userIri : null;
    });

    const userEmail: ComputedRef<string | null> = computed(() => {
        return decoded.value ? decoded.value.email : null;
    });

    const userRoles: ComputedRef<string[]> = computed(() => {
        return decoded.value ? decoded.value.roles : [];
    });

    const userUuid: ComputedRef<string | null> = computed(() => {
        return userIri.value ? getUuidFromIri(userIri.value) : null;
    });

    return { userIri, userEmail, userRoles, userUuid };
}
