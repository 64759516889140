
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "Footer",
    setup() {
        const { locale } = useI18n();
        return { locale };
    },
});
