import { ThemeDto, TranslationThemeDto } from "../dto/theme";
import { useAPI } from "../api";
import { useApiResource } from "../api-resource";
import axios, { AxiosResponse } from "axios";
import { HydraCollection } from "../dto/hydra";
import { Resource, ResourceData, Whole } from "../util/types";

export interface PatchThemeData {
    id: string;
    enTranslation?: TranslationThemeDto;
    daTranslation?: TranslationThemeDto;
    artworks?: string[];
    picture?: string;
    copyright?: string;
    sortNumber?: number;
}

export interface ThemeQueryParams {
    page: string;
    itemsPerPage?: string;
    pagination?: string;
    "order[sortNumber]"?: string;
    promoteToFront?: string;
    "artworks.id"?: string;
}

export function useThemes() {
    const { http } = useAPI();

    const getThemes = (params: ThemeQueryParams): Promise<AxiosResponse<HydraCollection<ThemeDto>>> => {
        return http.get<HydraCollection<ThemeDto>>(`/api/themes`, { params });
    };

    const getTheme = (id: string): Promise<AxiosResponse<ThemeDto>> => {
        return http.get<ThemeDto>(`/api/themes/${id}`);
    };

    const createTheme = (data: ResourceData<ThemeDto>): Promise<AxiosResponse<Resource>> => {
        return http.post<ThemeDto>(`/api/themes`, data);
    };

    const patchTheme = (id: string, data: PatchThemeData): Promise<AxiosResponse<Whole<PatchThemeData>>> => {
        return http.patch<ThemeDto>(`/api/themes/${id}`, data);
    };

    const patchMultipleThemes = (themes: PatchThemeData[]) => {
        return axios.all(
            themes.map((theme) => {
                return patchTheme(theme.id, theme);
            })
        );
    };

    const deleteTheme = (id: string): Promise<AxiosResponse<void>> => {
        return http.delete(`/api/themes/${id}`);
    };

    return {
        getThemes,
        getTheme,
        createTheme,
        patchTheme,
        patchMultipleThemes,
        deleteTheme,
    };
}

export function useThemesResource(params: ThemeQueryParams) {
    return useApiResource<ThemeDto, ThemeQueryParams>(`/api/themes`, params);
}
