import { PresavedSearchDto } from "../dto/presaved-search";
import { useAPI } from "../api";
import { useApiResource } from "../api-resource";
import { AxiosResponse } from "axios";
import { HydraCollection } from "../dto/hydra";
import { Resource, ResourceData, Whole } from "../util/types";

export interface PatchPreasavedSearchData {
    labelDaTranslation?: string;
    labelEnTranslation?: string;
    link?: string;
}

export interface PreasavedSearchQueryParams {
    page: string;
    pagination?: string;
}

export function usePresavedSearches() {
    const { http } = useAPI();

    const getPresavedSearches = (
        params: PreasavedSearchQueryParams
    ): Promise<AxiosResponse<HydraCollection<PresavedSearchDto>>> => {
        return http.get<HydraCollection<PresavedSearchDto>>(`/api/presaved_searches`, { params });
    };

    const getPresavedSearch = (id: string): Promise<AxiosResponse<PresavedSearchDto>> => {
        return http.get<PresavedSearchDto>(`/api/presaved_searches/${id}`);
    };

    const createPresavedSearch = (data: ResourceData<PresavedSearchDto>): Promise<AxiosResponse<Resource>> => {
        return http.post<PresavedSearchDto>(`/api/presaved_searches`, data);
    };

    const patchPresavedSearch = (
        id: string,
        data: PatchPreasavedSearchData
    ): Promise<AxiosResponse<Whole<PatchPreasavedSearchData>>> => {
        return http.patch<PresavedSearchDto>(`/api/presaved_searches/${id}`, data);
    };

    const deletePresavedSearch = (id: string): Promise<AxiosResponse<void>> => {
        return http.delete(`/api/presaved_searches/${id}`);
    };

    return {
        getPresavedSearches,
        getPresavedSearch,
        createPresavedSearch,
        patchPresavedSearch,
        deletePresavedSearch,
    };
}

export function usePresavedSearchesResource(params: PreasavedSearchQueryParams) {
    return useApiResource<PresavedSearchDto, PreasavedSearchQueryParams>(`/api/presaved_searches`, params);
}
