import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeSection = _resolveComponent("HomeSection")!
  const _component_PopularCollections = _resolveComponent("PopularCollections")!
  const _component_ThemesSection = _resolveComponent("ThemesSection")!
  const _component_OnDisplaySection = _resolveComponent("OnDisplaySection")!
  const _component_LoginPopup = _resolveComponent("LoginPopup")!
  const _component_RegisterPopup = _resolveComponent("RegisterPopup")!
  const _component_ForgotPasswordPopup = _resolveComponent("ForgotPasswordPopup")!
  const _component_CreatePasswordPopup = _resolveComponent("CreatePasswordPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HomeSection),
    _createVNode(_component_PopularCollections),
    _createVNode(_component_ThemesSection),
    _createVNode(_component_OnDisplaySection),
    (_ctx.view === 'login')
      ? (_openBlock(), _createBlock(_component_LoginPopup, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.view === 'register')
      ? (_openBlock(), _createBlock(_component_RegisterPopup, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.view === 'forgot-password')
      ? (_openBlock(), _createBlock(_component_ForgotPasswordPopup, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.view === 'create-password')
      ? (_openBlock(), _createBlock(_component_CreatePasswordPopup, { key: 3 }))
      : _createCommentVNode("", true)
  ], 64))
}