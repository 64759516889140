
import { defineComponent, PropType } from "vue";

export type SpinnerSize = "xs" | "sm" | "md" | "bg";

export default defineComponent({
    name: "Spinner",
    props: {
        size: {
            type: String as PropType<SpinnerSize>,
            default: "md",
        },
    },
});
