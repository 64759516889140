
import { defineComponent } from "vue";
import { useAPI } from "../api";

export default defineComponent({
    name: "IsNotAuthenticated",
    setup() {
        const { isAuthenticated } = useAPI();
        return { isAuthenticated };
    },
});
