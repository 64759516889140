import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c27f832"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "presaved-searches"
}
const _hoisted_2 = { class: "presaved-searches-title" }
const _hoisted_3 = { class: "has-text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Block = _resolveComponent("Block")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.entities.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("landing-page.suggestions-for-searches")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            onClick: ($event: any) => (_ctx.$emit('redirect', entity.link)),
            to: entity.link,
            key: entity['id'],
            class: "mx-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Block, {
                class: _normalizeClass(["presaved-searches-box px-4 py-2", { light: _ctx.light }])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(entity[_ctx.labelProp]), 1)
                ]),
                _: 2
              }, 1032, ["class"])
            ]),
            _: 2
          }, 1032, ["onClick", "to"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}