import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-239aef90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "artwork-card" }
const _hoisted_2 = {
  key: 1,
  class: "far fa-image",
  "data-testid-image-icon": ""
}
const _hoisted_3 = { class: "artwork-card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!
  const _component_Label = _resolveComponent("Label")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { name: 'ArtworkPage', params: { id: _ctx.artworkId } },
      class: "artwork-card-title"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["artwork-card-image", [{ big: _ctx.big }, { 'full-height': _ctx.fullHeight }]]),
          "data-testid-card-image": ""
        }, [
          (_ctx.imgSrc)
            ? (_openBlock(), _createBlock(_component_SkeletonLoader, {
                key: 0,
                imgSrc: _ctx.imgSrc + '/download?width=650&height=550'
              }, null, 8, ["imgSrc"]))
            : _createCommentVNode("", true),
          (!_ctx.imgSrc)
            ? (_openBlock(), _createElementBlock("i", _hoisted_2))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Label, null, {
            title: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title", {}, undefined, true)
            ]),
            subtitle: _withCtx(() => [
              _renderSlot(_ctx.$slots, "creators", {}, undefined, true)
            ]),
            _: 3
          })
        ])
      ]),
      _: 3
    }, 8, ["to"])
  ]))
}