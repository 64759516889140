import { App, inject, reactive } from "vue";

export type NotificationType = "success" | "danger" | "info";

export interface Notification {
    type: NotificationType;
    message: string;
    timestamp?: number;
}

export interface NotificationsPlugin {
    notifications: Notification[];
    push(notif: Notification): Notification;
    remove(notif: Notification): void;
}

export interface NotificationsPluginMock extends NotificationsPlugin {
    install(app: App): void;
}

export const NOTIFICATIONS_KEY = Symbol("NotificationsModule");

export function useNotifications(): NotificationsPlugin {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return inject<NotificationsPlugin>(NOTIFICATIONS_KEY)!;
}

export function createNotifications(): NotificationsPlugin {
    const notifications = reactive<Notification[]>([]);

    function push(notif: Notification): Notification {
        const clone = { ...notif, timestamp: Date.now() };
        notifications.push(clone);
        return clone;
    }

    function remove(notif: Notification): void {
        const index = notifications.indexOf(notif);
        if (index !== -1) {
            notifications.splice(index, 1);
        }
    }

    return {
        notifications,
        push,
        remove,
    };
}

export function createNotificationsMock(): NotificationsPluginMock {
    const { notifications, push, remove } = createNotifications();

    return {
        notifications,
        push,
        remove,
        install(app: App) {
            app.provide(NOTIFICATIONS_KEY, this);
        },
    };
}

const notifications = createNotifications();
export default notifications;
