import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "maincontent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkipNavigation = _resolveComponent("SkipNavigation")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SkipNavigation),
    _createVNode(_component_Header, { landingPage: _ctx.isLandingPage }, null, 8, ["landingPage"]),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_Footer)
  ], 64))
}