import { UserCollectionDto } from "../dto/user-collection";
import { useAPI } from "../api";
import axios, { AxiosResponse } from "axios";
import { HydraCollection } from "../dto/hydra";
import { useApiResource } from "../api-resource";

export interface UserCollectionQueryParams {
    page: string;
    pagination?: string;
    owner?: string;
    onlyPublic?: string;
    itemsPerPage?: number;
    "artworks.artwork.id"?: string;
    "order[count]"?: string;
    "order[title]"?: string;
}

export interface PatchUserCollectionDto {
    title: string;
    public: boolean;
    owner: string;
    artworks: Array<{
        artwork: string;
        collection: string;
        sortNumber: number;
    }>;
}

export interface CollectArtworkDto {
    userCollection: string;
    artwork: string;
    sortNumber: number;
}

export function useUserCollections() {
    const { http } = useAPI();

    const getUserCollection = (id: string): Promise<AxiosResponse<UserCollectionDto>> => {
        return http.get<UserCollectionDto>(`/api/user_collections/${id}`);
    };

    const getUserCollections = (
        params: UserCollectionQueryParams
    ): Promise<AxiosResponse<HydraCollection<UserCollectionDto>>> => {
        return http.get<HydraCollection<UserCollectionDto>>(`/api/user_collections`, { params });
    };

    const postUserCollection = (data: Partial<UserCollectionDto>): Promise<AxiosResponse<UserCollectionDto>> => {
        return http.post<UserCollectionDto>(`/api/user_collections`, data);
    };

    const postCollectArtwork = (data: CollectArtworkDto): Promise<AxiosResponse<CollectArtworkDto>> => {
        return http.post<CollectArtworkDto>(`/api/user_collections/collect-artwork`, data);
    };

    const postMultipleCollectArtwork = (data: CollectArtworkDto[]) => {
        return axios.all(
            data.map((item) => {
                return postCollectArtwork(item);
            })
        );
    };

    const postRemoveCollectionArtwork = (
        data: Partial<CollectArtworkDto>
    ): Promise<AxiosResponse<CollectArtworkDto>> => {
        return http.post<CollectArtworkDto>(`/api/user_collections/remove-collection-artwork`, data);
    };

    const patchUserCollection = (
        id: string,
        data: Partial<PatchUserCollectionDto>
    ): Promise<AxiosResponse<UserCollectionDto>> => {
        return http.patch<UserCollectionDto>(`/api/user_collections/${id}`, data);
    };

    const deleteUserCollection = (id: string): Promise<AxiosResponse<void>> => {
        return http.delete(`/api/user_collections/${id}`);
    };

    return {
        getUserCollection,
        getUserCollections,
        postUserCollection,
        postCollectArtwork,
        patchUserCollection,
        deleteUserCollection,
        postMultipleCollectArtwork,
        postRemoveCollectionArtwork,
    };
}

export function useUserCollectionsResource(params: UserCollectionQueryParams) {
    return useApiResource<UserCollectionDto, UserCollectionQueryParams>(`/api/user_collections`, params);
}
