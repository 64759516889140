
import { AcquisitionDto, ThemeDto } from "@kunsten/core";
import { computed, defineComponent, PropType } from "vue";
import Label from "./Label.vue";
import Translation from "./Translation.vue";
import TranslationAlt from "./TranslationAlt.vue";
import CopyrightHover from "./CopyrightHover.vue";

export default defineComponent({
    name: "BigCard",
    components: { Label, Translation, TranslationAlt, CopyrightHover },
    props: {
        entity: {
            type: Object as PropType<ThemeDto | AcquisitionDto>,
            required: true,
        },
    },
    setup(props) {
        const entityPath = computed(() => {
            // slicing /api/entities/123 to /entities/123
            return props.entity["@id"].slice(4, props.entity["@id"].length);
        });
        return { entityPath };
    },
});
