
import { defineComponent } from "vue";

export default defineComponent({
    name: "Block",
    props: {
        bold: {
            type: Boolean,
            default: false,
        },
        action: {
            type: Boolean,
            default: true,
        },
    },
});
