
import { computed, defineComponent, PropType } from "vue";
import { useAPI } from "../api";

export default defineComponent({
    name: "HasRole",
    props: {
        role: [String, Array] as PropType<string[] | string>,
        ignoreAdministrators: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { role } = useAPI();

        const hasRole = computed(() => {
            if (!role.value) return false;
            if (!props.ignoreAdministrators && role.value === "ROLE_ADMIN") return true;
            if (Array.isArray(props.role)) return props.role.includes(role.value);
            if (props.role) return props.role === role.value;
            return false;
        });

        return { hasRole };
    },
});
