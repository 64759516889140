import { Directive } from "vue";

export default {
    mounted(el, binding) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        el.tabIndex = binding.value ? 0 : -1;
    },
    updated(el, binding) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        el.tabIndex = binding.value ? 0 : -1;
    },
} as Directive<HTMLElement, () => void>;
