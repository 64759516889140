import { AboutCollectionPageDto } from "../dto/about-collection-page";
import { TranslationPageDto } from "../dto/page";
import { useAPI } from "../api";
import axios, { AxiosResponse } from "axios";
import { useApiResource } from "../api-resource";

export interface AboutCollectionsQueryParams {
    page: string;
    pagination?: string;
    "order[daTranslation.title]"?: string;
    "order[enTranslation.title]"?: string;
    "order[sortNumber]"?: string;
}

export interface PatchAboutCollectionData {
    id: string;
    enTranslation?: TranslationPageDto;
    daTranslation?: TranslationPageDto;
    sortNumber?: number;
}

export function useAboutCollections() {
    const { http } = useAPI();

    const getAboutCollections = (): Promise<AxiosResponse<AboutCollectionPageDto>> => {
        return http.get<AboutCollectionPageDto>(`/api/about_collections`);
    };

    const getAboutCollection = (id: string): Promise<AxiosResponse<AboutCollectionPageDto>> => {
        return http.get<AboutCollectionPageDto>(`/api/about_collections/${id}`);
    };

    const patchAboutCollections = (
        id: string,
        data: Partial<AboutCollectionPageDto>
    ): Promise<AxiosResponse<AboutCollectionPageDto>> => {
        return http.patch<AboutCollectionPageDto>(`/api/about_collections/${id}`, data);
    };

    const postAboutCollections = (data: AboutCollectionPageDto): Promise<AxiosResponse<AboutCollectionPageDto>> => {
        return http.post<AboutCollectionPageDto>(`/api/about_collections`, data);
    };

    const patchMultipleAboutCollections = (aboutCollections: PatchAboutCollectionData[]) => {
        return axios.all(
            aboutCollections.map((aboutCollection) => {
                return patchAboutCollections(aboutCollection.id, aboutCollection);
            })
        );
    };

    const deleteAboutCollections = (id: string): Promise<AxiosResponse<void>> => {
        return http.delete(`/api/about_collections/${id}`);
    };

    return {
        getAboutCollections,
        getAboutCollection,
        patchAboutCollections,
        postAboutCollections,
        patchMultipleAboutCollections,
        deleteAboutCollections,
    };
}

export function useAboutCollectionsResource(params: AboutCollectionsQueryParams) {
    return useApiResource<AboutCollectionPageDto, AboutCollectionsQueryParams>(`/api/about_collections`, params);
}
