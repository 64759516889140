import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16be2b6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { class: "columns is-multiline" }
const _hoisted_4 = { class: "column is-half-tablet" }
const _hoisted_5 = { class: "column is-half-tablet" }
const _hoisted_6 = {
  key: 0,
  class: "pt-4 pb-6 has-text-centered"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityHero = _resolveComponent("EntityHero")!
  const _component_BigCard = _resolveComponent("BigCard")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.entities.length)
      ? (_openBlock(), _createBlock(_component_EntityHero, {
          key: 0,
          entity: _ctx.entities[0]
        }, null, 8, ["entity"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities.slice(0, _ctx.entities.length / 2 + 1), (entity, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: entity['id']
      }, [
        (index > 0)
          ? (_openBlock(), _createElementBlock("section", {
              key: 0,
              class: _normalizeClass(["themes-section", { alt: index % 2 }]),
              "aria-label": _ctx.$t('theme.themes') + ` ${index}`
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_BigCard, {
                      class: "mb-6",
                      entity: _ctx.entities[index * 2 - 1]
                    }, null, 8, ["entity"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    (index * 2 < _ctx.entities.length)
                      ? (_openBlock(), _createBlock(_component_BigCard, {
                          key: 0,
                          class: "mb-6",
                          entity: _ctx.entities[index * 2]
                        }, null, 8, ["entity"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (index == 5 && _ctx.total > 11)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_router_link, {
                        to: { name: 'Themes' },
                        class: "btn gossamer is-size-5 py-5 px-6"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("landing-page.see-more-themes")), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_1))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 64))
}