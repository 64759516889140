import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54b22bab"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "big-card",
  "data-testid-big-card": ""
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyrightHover = _resolveComponent("CopyrightHover")!
  const _component_TranslationAlt = _resolveComponent("TranslationAlt")!
  const _component_Translation = _resolveComponent("Translation")!
  const _component_Label = _resolveComponent("Label")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { path: _ctx.entityPath }
    }, {
      default: _withCtx(() => [
        _createElementVNode("figure", null, [
          (_ctx.entity.picture)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.entity.picture + '/download?width=675&height=375',
                alt: ""
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.entity.copyright)
            ? (_openBlock(), _createBlock(_component_CopyrightHover, {
                key: 1,
                text: _ctx.entity.copyright
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_Label, {
          light: true,
          size: "lg"
        }, {
          title: _withCtx(() => [
            _createElementVNode("h2", null, [
              _createVNode(_component_TranslationAlt, {
                data: _ctx.entity,
                property: "title"
              }, null, 8, ["data"])
            ])
          ]),
          subtitle: _withCtx(() => [
            _createVNode(_component_Translation, {
              limit: 140,
              data: _ctx.entity,
              property: "description"
            }, null, 8, ["data"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}