
import { UserCollectionDto } from "@kunsten/core";
import { defineComponent, PropType } from "vue";
import IconText from "../../../shared/components/IconText.vue";

export default defineComponent({
    name: "CollectionBlockLabel",
    components: { IconText },
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
        // when owner interacts with actions public colors
        forcePublic: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {};
    },
});
