
import { defineComponent, ref, watch } from "vue";
import { useTaxonomies } from "@kunsten/core";

import Footer from "@/shared/components/Footer.vue"; // @ is an alias to /src
import Header from "@/shared/components/Header.vue"; // @ is an alias to /src
import SkipNavigation from "@/shared/components/SkipNavigation.vue";
import { useRoute, useRouter } from "vue-router";
import { changeLocale } from "@/i18n";
import { useI18n } from "vue-i18n";

export default defineComponent({
    components: {
        Footer,
        Header,
        SkipNavigation,
    },
    computed: {
        isLandingPage() {
            return this.$route.name === "LandingPage";
        },
    },
    created() {
        const route = useRoute();
        const router = useRouter();
        const langQuery = ref("");
        const { locale } = useI18n();
        this.$store.dispatch("setLoader", true);
        this.getAllTaxonomies().then((res) => {
            const taxonomies = Array.prototype.concat(...res.map((r) => r.data["hydra:member"]));
            this.$store.dispatch("setTaxonomies", taxonomies);
            this.$store.dispatch("setLoader", false);
        });

        watch(
            route,
            () => {
                // If user enter the site with link with lang query
                // then set this language
                if (route.query?.lang && !langQuery.value) {
                    langQuery.value = (route.query?.lang as string) || "";
                    changeLocale(langQuery.value);
                }
                // If there is no lang query in the route add one
                if (!route.query?.lang && route.name) {
                    router.replace({ name: route.name as string, query: { ...route.query, lang: locale.value } });
                }
            },
            { deep: true, immediate: true }
        );
    },
    setup() {
        const { getAllTaxonomies } = useTaxonomies();

        return { getAllTaxonomies };
    },
});
