
import { defineComponent, PropType } from "vue";
import Spinner from "./Spinner.vue";

type IconTextSize = "sm" | "md" | "bg";

export default defineComponent({
    name: "IconText",
    components: { Spinner },
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String as PropType<IconTextSize>,
            default: "sm",
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
});
