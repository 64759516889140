
import { defineComponent, ref } from "vue";
import Modal from "../../../shared/components/Modal.vue";
import Input from "../../../shared/components/Input.vue";
import Alert from "../../../shared/components/Alert.vue";
import Spinner from "../../../shared/components/Spinner.vue";
import useVuelidate from "@vuelidate/core";
import { useResetPasswordRequest } from "@kunsten/core";
import { email, required } from "@vuelidate/validators";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "ForgotPasswordPopup",
    components: { Modal, Input, Alert, Spinner },
    data() {
        return {
            email: "",
        };
    },
    validations() {
        return {
            email: { required, email },
        };
    },
    setup() {
        const v$ = useVuelidate();
        const router = useRouter();
        const backendError = ref("");
        const loading = ref(false);
        const { resetPasswordRequest } = useResetPasswordRequest();

        const submit = (email: string): void => {
            backendError.value = "";
            loading.value = true;
            resetPasswordRequest(email)
                .then(() => {
                    router.push({ name: "LandingPage", query: { view: "login" } });
                    loading.value = false;
                })
                .catch((err) => {
                    if (err.response) {
                        backendError.value = err.response.data.message;
                    } else {
                        backendError.value = err.message;
                    }
                    loading.value = false;
                });
        };

        return {
            v$,
            submit,
            backendError,
            loading,
        };
    },
});
