
import { defineComponent } from "vue";
import Label from "./Label.vue";
import SkeletonLoader from "./SkeletonLoader.vue";

export default defineComponent({
    name: "ArtworkCard",
    components: { Label, SkeletonLoader },
    props: {
        imgSrc: {
            type: String,
        },
        artworkId: {
            type: String,
        },
        big: {
            type: Boolean,
            default: false,
        },
        fullHeight: {
            type: Boolean,
            default: false,
        },
    },
});
