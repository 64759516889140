/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, ComputedRef, reactive, readonly, Ref, ref, watch, WritableComputedRef } from "vue";
import { HydraCollection } from "./dto/hydra";
import axios, { CancelTokenSource } from "axios";
import { useAPI } from "./api";
import { useNotifications } from "./notifications";
import { useI18n } from "vue-i18n";

const PAGE_SIZE = 20;

export function useApiResource<Entity, Params extends { page: string } = { page: string }>(
    url: string,
    params: Params = { page: "1" } as any
) {
    const reactiveParams = reactive<Params>({ ...params });
    const result = ref<HydraCollection<Entity> | null>(null);
    const loading = ref<boolean>(false);
    const error = ref<Error | null>(null);
    const { http } = useAPI();
    const { t } = useI18n();

    let cancelToken: CancelTokenSource | null = null;

    // when params change, but page stays the same, it means we need to update page to be back at 1
    watch(
        () => ({ ...reactiveParams }),
        (_params, _previousParams) => {
            if (_previousParams && _params.page === _previousParams.page) {
                reactiveParams.page = "1";
            }
        },
        { flush: "sync", deep: true }
    );

    watch(
        () => ({ ...reactiveParams }),
        (_params) => {
            if (cancelToken) cancelToken.cancel(t("general.cancel-message"));
            cancelToken = axios.CancelToken.source();

            loading.value = true;
            error.value = null;

            const filteredParams = Object.fromEntries(Object.entries(_params).filter((property) => !!property[1]));

            http.get<HydraCollection<Entity>>(url, {
                params: filteredParams,
                cancelToken: cancelToken.token,
            }).then(
                (res) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    result.value = res.data as any;
                    loading.value = false;
                },
                (err) => {
                    if (err?.constructor?.name === "Cancel") return;

                    error.value = err;
                    loading.value = false;
                    result.value = null;
                }
            );
        },
        { immediate: true, deep: true }
    );

    const refresh = () => {
        loading.value = true;
        error.value = null;

        const filteredParams = Object.fromEntries(Object.entries(reactiveParams).filter((property) => !!property[1]));

        http.get<HydraCollection<Entity>>(url, {
            params: filteredParams,
        }).then(
            (res) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                result.value = res.data as any;
                loading.value = false;
            },
            (err) => {
                if (err?.constructor?.name === "Cancel") return;

                error.value = err;
                loading.value = false;
                result.value = null;
            }
        );
    };

    const entities = computed(() => (result.value ? result.value["hydra:member"] : []));
    const total = computed(() => (result.value ? result.value["hydra:totalItems"] : 0));
    const page: WritableComputedRef<number> = computed({
        get: () => parseInt(reactiveParams.page),
        set: (value: number) => (reactiveParams.page = value.toString()),
    });
    const pages = computed(() => (total.value ? Math.ceil(total.value / PAGE_SIZE) : 1));

    const changePage = (page: string | number) => {
        reactiveParams.page = typeof page === "number" ? page.toString() : page;
    };
    const nextPage = () => (reactiveParams.page = Math.min(pages.value, parseInt(reactiveParams.page) + 1).toString());
    const prevPage = () => (reactiveParams.page = Math.max(1, parseInt(reactiveParams.page) - 1).toString());
    const loaded = computed(() => !loading.value && !error.value);

    return {
        params: reactiveParams,
        error,
        result,
        loading,
        loaded,
        entities,
        total,
        page,
        pages,
        changePage,
        nextPage,
        prevPage,
        refresh,
    };
}

export function useDeleteResource() {
    const { http } = useAPI();
    const loading = ref(false);
    const error = ref<any | null>(null);
    const n$ = useNotifications();
    const { t } = useI18n();

    const httpDelete = (resource: string, id: string): Promise<any> => {
        return http.delete(`/api/${resource}/${id}`);
    };

    const deleteResource = (resource: string, id: string) => {
        loading.value = true;
        error.value = null;

        httpDelete(resource, id).then(
            () => {
                loading.value = false;
                n$.push({
                    type: "success",
                    message: t("notification.deleted"),
                });
            },
            (err) => {
                loading.value = false;
                error.value = err;
            }
        );
    };

    return {
        loading: readonly(loading),
        error: readonly(error),
        deleteResource,
    };
}

export function useSingleResource<T extends Record<string, unknown>>(
    resource: Ref<string> | ComputedRef<string>,
    id: Ref<string> | ComputedRef<string>,
    params: Record<string, string | string[]> = {}
) {
    const loading = ref(false);
    const entity = ref<T | null>(null);
    const error = ref<any | null>(null);
    const reactiveParams = reactive({ ...params });
    const { http } = useAPI();
    const n$ = useNotifications();
    const { t } = useI18n();

    const httpGet = (params: Record<string, string | string[]>): Promise<any> => {
        return http.get(`/api/${resource.value}/${id.value}`, { params });
    };

    const load = (params: Record<string, string | string[]>) => {
        loading.value = true;
        entity.value = null;
        error.value = null;

        if (id.value) {
            httpGet(params).then(
                (res) => {
                    console.log(res);
                    loading.value = false;
                    entity.value = res.data;
                },
                (err) => {
                    loading.value = false;
                    error.value = err;
                }
            );
        }
    };

    watch([() => reactiveParams, () => resource.value, () => id.value], ([params]) => load(params), {
        deep: true,
        immediate: true,
    });

    const save = (data: T) => {
        loading.value = true;
        http.patch(`/api/${resource.value}/${id.value}`, data)
            .then(() => httpGet(reactiveParams))
            .then((res) => {
                n$.push({
                    type: "success",
                    message: t("notification.saved"),
                });
                loading.value = false;
                entity.value = res.data;
            })
            .catch((err) => {
                const data = err.response?.data;
                if (data) {
                    error.value = data["hydra:description"] ?? error.value;
                } else {
                    error.value = err;
                }
                loading.value = false;
            });
    };

    return {
        loading: readonly(loading),
        entity: readonly(entity),
        error: readonly(error),
        params: reactiveParams,
        save,
    };
}
