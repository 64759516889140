
import { defineComponent, computed } from "vue";
import { IsNotAuthenticated, IsAuthenticated } from "@kunsten/core";
import { useAPI } from "@kunsten/core";
import { useI18n } from "vue-i18n";
import ArtWorkSearch from "@/modules/landing-page/shared/components/ArtWorkSearch.vue";

export default defineComponent({
    name: "MobileNav",
    components: {
        ArtWorkSearch,
        IsNotAuthenticated,
        IsAuthenticated,
    },
    props: {
        closed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            popupHeight: 0,
        };
    },
    mounted() {
        this.popupHeight = (this.$refs.popup as HTMLDivElement).scrollHeight + 8;
    },
    setup() {
        const { logout, userData } = useAPI();
        const { locale } = useI18n();

        const userId = computed(() => {
            return userData.value?.id;
        });

        return { logout, userId, locale };
    },
    methods: {
        close() {
            this.$emit("update:closed", true);
        },
    },
    watch: {
        closed: function (value) {
            if (!value) (this.$refs.popup as HTMLDivElement).focus();
        },
    },
});
