/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from "axios";
import { computed, inject, reactive, readonly, ref, watch } from "vue";
import { TokenDto } from "./dto/token";
import { Deferred } from "./util/deferred";
import { UserDto } from "./dto/user";
import { identity } from "./main";
import { parseToken } from "./auth/token";

export type Credentials = TokenDto | { token: null; refresh_token: null };

export const KUNSTEN_API_KEY = Symbol("KunstenApi");
export const KUNSTEN_HTTP_KEY = Symbol("KunstenApiHttp");

export function createApi() {
    const TOKEN_MIN_TTL = 30 * 1000;

    const http: AxiosInstance = axios.create();
    const whenReady = Deferred<void>();
    const credentials = reactive<Credentials>({
        token: null,
        refresh_token: null,
    });
    const userData = ref<UserDto | null>(null);
    let _refreshTimer: number | null = null;

    initTokenPersistence();
    initInterceptors();
    initUserData();

    function initTokenPersistence() {
        const token = window.localStorage.getItem("kunsten_token");
        const refresh_token = window.localStorage.getItem("kunsten_refresh_token");

        if (token && refresh_token) {
            const { exp } = parseToken(token);
            const millisTillExpiry = exp * 1000 - Date.now();

            if (millisTillExpiry > TOKEN_MIN_TTL) {
                updateCredentials({ token, refresh_token });
                makeReady();
            } else {
                refreshToken(refresh_token)
                    .then(() => {
                        makeReady();
                    })
                    .catch((err: Error) => {
                        console.error(err);
                        console.error("Failed to refresh token");

                        removeCredentials();
                        makeReady();
                    });
            }
        } else {
            makeReady();
        }

        watch(
            () => credentials,
            (credentials) => {
                if (credentials.token) {
                    window.localStorage.setItem("kunsten_token", credentials.token);
                } else {
                    window.localStorage.removeItem("kunsten_token");
                }

                if (credentials.refresh_token) {
                    window.localStorage.setItem("kunsten_refresh_token", credentials.refresh_token);
                } else {
                    window.localStorage.removeItem("kunsten_refresh_token");
                }
            },
            { immediate: true, deep: true }
        );
    }

    function initInterceptors() {
        http.interceptors.request.use((config) => {
            if (credentials.token && config.headers) {
                config.headers.Authorization = `Bearer ${credentials.token}`;
            }
            return config;
        });

        http.interceptors.request.use((config) => {
            if (config.method?.toUpperCase() === "PATCH" && config.headers) {
                config.headers["Content-Type"] = "application/merge-patch+json";
            }
            return config;
        });

        // Replace error message with api provided message if we have it
        http.interceptors.response.use(identity, (err: any) => {
            if (err.response?.data?.message) err.message = err.response.data.message;
            return Promise.reject(err);
        });
    }

    function initUserData() {
        watch(
            () => credentials.token,
            (token) => {
                if (token) {
                    const { userIri } = parseToken(token);
                    http.get<UserDto>(userIri)
                        .then((res) => {
                            userData.value = res.data;
                        })
                        .catch((e) => {
                            console.error(e?.response?.data?.message || e.message);
                            userData.value = null;
                        });
                } else {
                    userData.value = null;
                }
            },
            { immediate: true }
        );
    }

    function updateCredentials({ token, refresh_token }: TokenDto): void {
        const { exp } = parseToken(token);
        const millisTillExpiry = exp * 1000 - Date.now();

        if (millisTillExpiry <= 0) throw new Error("Token is expired");
        if (millisTillExpiry <= TOKEN_MIN_TTL) {
            throw new Error(`Token lifespan cannot be shorter than ${TOKEN_MIN_TTL / 1000}s`);
        }

        removeCredentials();
        credentials.token = token;
        credentials.refresh_token = refresh_token;

        _refreshTimer = setTimeout(() => {
            refreshToken().catch(() => {
                removeCredentials();
            });
        }, millisTillExpiry - TOKEN_MIN_TTL);
    }

    function removeCredentials(): void {
        if (_refreshTimer) {
            clearTimeout(_refreshTimer);
            _refreshTimer = null;
        }

        credentials.token = null;
        credentials.refresh_token = null;
    }

    function refreshToken(overrideRefreshToken?: string): Promise<TokenDto> {
        const refresh_token = overrideRefreshToken ?? credentials.refresh_token;
        if (!refresh_token) return Promise.reject(new Error("Missing refresh token."));

        return http.post<TokenDto>(`/api/auth/token/refresh`, { refresh_token }).then((res) => {
            const { token, refresh_token } = res.data;
            updateCredentials({ token, refresh_token });
            return res.data;
        });
    }

    function makeReady(): void {
        whenReady.resolve();
    }

    function setApiLocale(locale: string): void {
        // eslint-disable-next-line
        // @ts-ignore
        http.defaults.headers["Accept-Language"] = locale;
    }

    const isAuthenticated = computed(() => !!credentials.token);
    const role = computed(() => (credentials.token ? parseToken(credentials.token).roles[0] : null));

    return {
        http,
        updateCredentials,
        removeCredentials,
        refreshToken,
        logout: removeCredentials,
        whenReady: whenReady as Promise<void>,
        credentials: readonly(credentials),
        isAuthenticated,
        role,
        userData,
        setApiLocale,
    };
}

export type KunstenApi = ReturnType<typeof createApi>;

export function useAPI(): KunstenApi {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return inject<KunstenApi>(KUNSTEN_API_KEY)!;
}

export function useHttp(): AxiosInstance {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return inject<AxiosInstance>(KUNSTEN_HTTP_KEY)!;
}
