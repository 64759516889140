import { ref, Ref } from "vue";
import { useHttp } from "../api";
import { FiltersData } from "./filters";
import { EsArtworksApiResponse, INITIAL_FILTER_OPTIONS, SEARCH_API_URL, UseSearchFilterOptions } from "./useSearch";

const filterOptionsCache = new Map<string, Readonly<{ lang: string; options: UseSearchFilterOptions }>>();

const ALL_KEY = "ALL_FILTER_OPTIONS";

export const useFilterOptions = (language: Ref<string>, immediate = true) => {
    const http = useHttp();
    const loading = ref<boolean>(false);
    const filterOptions = ref<UseSearchFilterOptions>(INITIAL_FILTER_OPTIONS);

    const getFilterOptions = (filters?: FiltersData): Promise<UseSearchFilterOptions> => {
        loading.value = true;

        const cachedAllFilters = filterOptionsCache.get(ALL_KEY);

        if (!filters && cachedAllFilters && cachedAllFilters.lang === language.value) {
            filterOptions.value = cachedAllFilters.options;

            return new Promise((resolve) => {
                loading.value = false;
                return resolve(cachedAllFilters.options);
            });
        }

        const params = {
            lang: language.value,
            ...filters,
            page: 1,
            itemsPerPage: 0,
        };

        return http
            .get<EsArtworksApiResponse>(SEARCH_API_URL, { params })
            .then((res) => {
                loading.value = false;
                filterOptions.value = res.data["hydra:es:aggregation"];
                if (!filters) filterOptionsCache.set(ALL_KEY, { lang: language.value, options: filterOptions.value });
                return filterOptions.value;
            })
            .catch((e: Error) => {
                loading.value = false;
                throw e;
            });
    };

    if (immediate) getFilterOptions();

    return { getFilterOptions, loading, filterOptions };
};
