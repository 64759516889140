/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-non-null-assertion */

export type ResolveFn<T> = (value: T | PromiseLike<T>) => void;
export type RejectFn = (err?: any) => void;
export type Deferred<T> = Promise<T> & { resolve: ResolveFn<T>; reject: RejectFn };

export function Deferred<T>(): Deferred<T> {
    let resolve: ResolveFn<T>;
    let reject: RejectFn;
    const promise: any = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });
    promise.reject = reject!;
    promise.resolve = resolve!;
    return promise;
}
