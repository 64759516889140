import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e9c1b50"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "on-display-section",
  "aria-labelledby": "on-display-section-heading"
}
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = {
  id: "on-display-section-heading",
  class: "on-display-section-title pb-6 has-text-weight-medium has-text-white"
}
const _hoisted_4 = { class: "py-4 has-text-centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArtworkSlider = _resolveComponent("ArtworkSlider")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.entities.length)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("landing-page.on-display-at-the-museum")), 1),
          _createVNode(_component_ArtworkSlider, { entities: _ctx.entities }, null, 8, ["entities"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: { name: 'Search', query: { onDisplay: true, lang: _ctx.locale, page: '1' } },
              class: "btn gossamer is-size-5 py-5 px-6"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("landing-page.show-more-on-display")), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}