
import { defineComponent } from "vue";

export default defineComponent({
    name: "SwipeArrows",
    methods: {
        prev() {
            this.$emit("prev");
        },
        next() {
            this.$emit("next");
        },
    },
});
