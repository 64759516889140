import { AcquisitionDto, TranslationAcquisitionDto } from "../dto/acquisition";
import { useAPI } from "../api";
import { useApiResource } from "../api-resource";
import axios, { AxiosResponse } from "axios";
import { HydraCollection } from "../dto/hydra";
import { Resource, ResourceData, Whole } from "../util/types";

export interface PatchAcquisitionData {
    id: string;
    enTranslation?: TranslationAcquisitionDto;
    daTranslation?: TranslationAcquisitionDto;
    artworks?: string[];
    picture?: string;
    artworkSearchData?: string;
}

export interface AcquisitionQueryParams {
    page: string;
    pagination?: string;
    "order[sortNumber]"?: string;
}

export function useAcquisitions() {
    const { http } = useAPI();

    const getAcquisitions = (
        params: AcquisitionQueryParams
    ): Promise<AxiosResponse<HydraCollection<AcquisitionDto>>> => {
        return http.get<HydraCollection<AcquisitionDto>>(`/api/acquisitions`, { params });
    };

    const getAcquisition = (id: string): Promise<AxiosResponse<AcquisitionDto>> => {
        return http.get<AcquisitionDto>(`/api/acquisitions/${id}`);
    };

    const createAcquisition = (data: ResourceData<AcquisitionDto>): Promise<AxiosResponse<Resource>> => {
        return http.post<AcquisitionDto>(`/api/acquisitions`, data);
    };

    const patchAcquisition = (
        id: string,
        data: PatchAcquisitionData
    ): Promise<AxiosResponse<Whole<PatchAcquisitionData>>> => {
        return http.patch<AcquisitionDto>(`/api/acquisitions/${id}`, data);
    };

    const patchMultipleAcquisitions = (acquisitions: PatchAcquisitionData[]) => {
        return axios.all(
            acquisitions.map((acquisition) => {
                return patchAcquisition(acquisition.id, acquisition);
            })
        );
    };

    const deleteAcquisition = (id: string): Promise<AxiosResponse<void>> => {
        return http.delete(`/api/acquisitions/${id}`);
    };

    return {
        getAcquisitions,
        getAcquisition,
        createAcquisition,
        patchAcquisition,
        patchMultipleAcquisitions,
        deleteAcquisition,
    };
}

export function useAcquisitionsResource(params: AcquisitionQueryParams) {
    return useApiResource<AcquisitionDto, AcquisitionQueryParams>(`/api/acquisitions`, params);
}
