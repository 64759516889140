import { createStore } from "vuex";
import { TranslatableEntity } from "@kunsten/core";

export const SET_TAXONOMIES = "SET_TAXONOMIES";
export const ADD_TAXONOMIES = "ADD_TAXONOMIES";
export const ADD_TAXONOMY = "ADD_TAXONOMY";
export const UPDATE_TAXONOMY = "UPDATE_TAXONOMY";

export const SET_LOADER = "SET_LOADER";

export default createStore({
    state: {
        taxonomies: [] as TranslatableEntity<string>[],
        loading: false, // global taxonomy loader
    },
    mutations: {
        [SET_TAXONOMIES](state, taxonomies: TranslatableEntity<string>[]) {
            state.taxonomies = taxonomies;
        },
        [ADD_TAXONOMY](state, taxonomy: TranslatableEntity<string>) {
            state.taxonomies = [...state.taxonomies, taxonomy];
        },
        [ADD_TAXONOMIES](state, taxonomies: TranslatableEntity<string>[]) {
            state.taxonomies = [...state.taxonomies.filter((t) => !taxonomies.includes(t)), ...taxonomies];
        },
        [UPDATE_TAXONOMY](state, taxonomy: TranslatableEntity<string>) {
            state.taxonomies = [...state.taxonomies.filter((t) => t["@id"] !== taxonomy["@id"]), taxonomy];
        },
        [SET_LOADER](state, isLoading: boolean) {
            state.loading = isLoading;
        },
    },
    actions: {
        setTaxonomies({ commit }, taxonomies) {
            commit(SET_TAXONOMIES, taxonomies);
        },
        addTaxonomy({ commit }, taxonomy) {
            commit(ADD_TAXONOMY, taxonomy);
        },
        addTaxonomies({ commit }, taxonomies) {
            commit(ADD_TAXONOMIES, taxonomies);
        },
        updateTaxonomy({ commit }, taxonomy) {
            commit(UPDATE_TAXONOMY, taxonomy);
        },
        setLoader({ commit }, isLoading) {
            commit(SET_LOADER, isLoading);
        },
    },
    modules: {},
    getters: {
        getTaxonomyById: (state) => (id: string) => {
            return state.taxonomies.find((t) => t["@id"] === id);
        },
        isLoading: (state) => {
            return state.loading;
        },
    },
});
