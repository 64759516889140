
import { defineComponent } from "vue";
import SwipeArrows from "./SwipeArrows.vue";

export default defineComponent({
    name: "Slider",
    components: { SwipeArrows },
    props: {
        entities: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            activeIndex: 0,
        };
    },
    computed: {
        cards(): number {
            return this.entities.length;
        },
    },
    methods: {
        prev() {
            if (this.activeIndex > 0) {
                this.activeIndex--;
                this.move();
            }
        },
        next() {
            if (this.activeIndex < this.cards - 1) {
                this.activeIndex++;
                this.move();
            }
        },
        move() {
            const boxWidth = ((this.$refs.container as HTMLDivElement).scrollWidth - 8 * this.cards) / this.cards;
            const scrollTo = boxWidth * this.activeIndex;
            (this.$refs.container as HTMLDivElement).scrollLeft = scrollTo;
        },
        onScroll(e: Event) {
            const position = (e.currentTarget as HTMLDivElement).scrollLeft;
            const boxWidth = ((this.$refs.container as HTMLDivElement).scrollWidth - 8 * this.cards) / this.cards;
            this.activeIndex = Math.floor((position + boxWidth / 2) / boxWidth);
        },
    },
});
