/* eslint-disable @typescript-eslint/no-explicit-any,no-empty */
import { ObjectDirective } from "vue";

class TrapFocus {
    preNode: HTMLSpanElement;
    postNode: HTMLSpanElement;
    previouslyFocusedElement: Element | null = null;
    ignoreListener = false;
    lastFocusedElement: Element | null = null;

    constructor(private el: HTMLElement, initialBinding: boolean) {
        this.focusListener = this.focusListener.bind(this);
        this.preNode = this.createTrapNode();
        this.postNode = this.createTrapNode();
        this.updateBinding(initialBinding);
    }

    private binding = false;

    updateBinding(binding: boolean) {
        if (binding && !this.binding) {
            this.previouslyFocusedElement = document.activeElement;
            document.addEventListener("focus", this.focusListener, true);
            this.el.parentNode?.insertBefore(this.preNode, this.el);
            this.el.parentNode?.insertBefore(this.preNode, this.el.nextElementSibling);
            this.focusFirst(this.el);
        } else if (!binding) {
            this.destroy();
        }

        this.binding = binding;
    }

    destroy(): void {
        document.removeEventListener("focus", this.focusListener);
        this.preNode.parentNode?.removeChild(this.preNode);
        this.postNode.parentNode?.removeChild(this.postNode);
        if (this.previouslyFocusedElement) {
            this.focus(this.previouslyFocusedElement);
            this.previouslyFocusedElement = null;
        }
        this.lastFocusedElement = null;
    }

    private focusListener(): void {
        if (!this.binding) return;
        if (this.ignoreListener) return;
        if (!document.activeElement) return;

        if (!this.el.contains(document.activeElement as HTMLElement)) {
            this.focusFirst(this.el);
            if (this.lastFocusedElement === document.activeElement) this.focusLast(this.el);
            this.lastFocusedElement = document.activeElement;
        }
    }

    private focusFirst(node: any): boolean {
        this.focus(node);
        if (document.activeElement === node) return true;

        const childNodes = Array.from(node.childNodes);
        for (const cn of childNodes) {
            const res = this.focusFirst(cn);
            if (res) return res;
        }

        return false;
    }

    private focusLast(node: any): boolean {
        this.focus(node);
        if (document.activeElement === node) return true;

        const childNodes = Array.from(node.childNodes).reverse();
        for (const cn of childNodes) {
            const res = this.focusLast(cn);
            if (res) return res;
        }

        return false;
    }

    private focus(el: Element): void {
        this.ignoreListener = true;

        try {
            (el as any)?.focus();
        } catch (e) {}

        this.ignoreListener = false;
    }

    private createTrapNode(): HTMLSpanElement {
        const span = document.createElement("span");
        span.tabIndex = 0;
        span.style.position = "fixed";
        span.style.left = "-10000";
        span.style.top = "0";
        return span;
    }
}

const FOCUS_MAP = new Map<HTMLElement, TrapFocus>();

export default {
    created(el, binding): void {
        FOCUS_MAP.set(el, new TrapFocus(el, binding.value));
    },
    updated(el, binding): void {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        FOCUS_MAP.get(el)!.updateBinding(binding.value);
    },
    beforeUnmount(el): void {
        FOCUS_MAP.get(el)?.destroy();
        FOCUS_MAP.delete(el);
    },
} as ObjectDirective<HTMLElement, boolean>;
