
import { defineComponent } from "vue";
import ArtworkSlider from "../../../../shared/components/ArtworkSlider.vue";
import { EsArtworkDto, useApiResource } from "@kunsten/core";
import { useI18n } from "vue-i18n";

export interface EsArtworkParams {
    page: string;
    itemsPerPage: string;
    lang: string;
    onDisplay: boolean;
}

export default defineComponent({
    name: "OnDisplaySection",
    components: {
        ArtworkSlider,
    },
    setup() {
        const { locale } = useI18n();

        const resource = useApiResource<EsArtworkDto, EsArtworkParams>("/api/es_artworks", {
            page: "1",
            itemsPerPage: "4",
            lang: locale.value,
            onDisplay: true,
        });
        return { ...resource, locale };
    },
});
