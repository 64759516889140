import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20adb91c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { "aria-labelledby": "entity-hero-heading" }
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = {
  key: 0,
  id: "entity-hero-heading"
}
const _hoisted_4 = {
  key: 1,
  id: "entity-hero-heading"
}
const _hoisted_5 = { class: "entity-hero-label-mobile page-container" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationAlt = _resolveComponent("TranslationAlt")!
  const _component_Translation = _resolveComponent("Translation")!
  const _component_Label = _resolveComponent("Label")!
  const _component_Box = _resolveComponent("Box")!
  const _component_CopyrightHover = _resolveComponent("CopyrightHover")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: "has-text-white",
      to: { path: _ctx.entityPath }
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "entity-hero",
          style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.entity.picture + '/download)' })
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_Box, null, {
              default: _withCtx(() => [
                _createVNode(_component_Label, { size: "lg" }, {
                  title: _withCtx(() => [
                    (_ctx.pageHeading)
                      ? (_openBlock(), _createElementBlock("h1", _hoisted_3, [
                          _createVNode(_component_TranslationAlt, {
                            data: _ctx.entity,
                            property: "title"
                          }, null, 8, ["data"])
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.pageHeading)
                      ? (_openBlock(), _createElementBlock("h2", _hoisted_4, [
                          _createVNode(_component_TranslationAlt, {
                            data: _ctx.entity,
                            property: "title"
                          }, null, 8, ["data"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  subtitle: _withCtx(() => [
                    _createVNode(_component_Translation, {
                      limit: 140,
                      data: _ctx.entity,
                      property: "description"
                    }, null, 8, ["data"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          (_ctx.entity.copyright)
            ? (_openBlock(), _createBlock(_component_CopyrightHover, {
                key: 0,
                text: _ctx.entity.copyright
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ], 4),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Label, { size: "lg" }, {
            title: _withCtx(() => [
              (_ctx.pageHeading)
                ? (_openBlock(), _createElementBlock("h1", _hoisted_6, [
                    _createVNode(_component_TranslationAlt, {
                      data: _ctx.entity,
                      property: "title"
                    }, null, 8, ["data"])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.pageHeading)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_7, [
                    _createVNode(_component_TranslationAlt, {
                      data: _ctx.entity,
                      property: "title"
                    }, null, 8, ["data"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            subtitle: _withCtx(() => [
              _createVNode(_component_Translation, {
                limit: 140,
                data: _ctx.entity,
                property: "description"
              }, null, 8, ["data"])
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}