import { KunstenDate } from "../dto/date";
import { computed } from "vue";

// default: yyyy-mm-dd
// slash: dd/mm yyyy
// year: yyyy
export type KunstenDateFormat = "default" | "slash" | "year";

export function useFormatDate() {
    const formatDate = (value: string | null | undefined, format: KunstenDateFormat): string => {
        if (!value) return "";

        if (value.length === 4) {
            format = 'year'
        }

        const date = new Date(value);
        const year = date.getFullYear().toString(10).padStart(4, "0");
        const month = (date.getMonth() + 1).toString(10).padStart(2, "0");
        const day = date.getDate().toString(10).padStart(2, "0");

        switch (format) {
            case "year":
                return year;
            case "slash":
                return `${day}/${month} ${year}`;
            default:
                return [year, month, day].join("-");
        }
    };

    return { formatDate };
}

export function useFormattedDate(value: KunstenDate | null | undefined, format: KunstenDateFormat, period: boolean) {
    const { formatDate } = useFormatDate();

    const formattedDate = computed(() => {
        if (!value) return "";

        let precisionDateToUse = value.precisionDate
        if (value.precisionDateString?.length === 4) {
            precisionDateToUse = value.precisionDateString
        }

        let endDateToUse = value.endDate
        if (value.endDateString?.length === 4) {
            endDateToUse = value.endDateString
        }

        let startDateToUse = value.startDate
        if (value.startDateString?.length === 4) {
            startDateToUse = value.startDateString
        }

        console.log(precisionDateToUse, endDateToUse, startDateToUse)

        if (period && startDateToUse && endDateToUse) {
            return `${formatDate(startDateToUse, format)} - ${formatDate(endDateToUse, format)}`;
        }
        return (
            formatDate(precisionDateToUse, format) ||
            formatDate(endDateToUse, format) ||
            formatDate(startDateToUse, format) ||
            ""
        );
    });

    return { formattedDate };
}

export function useFormattedEsDate(
    startDate: string | null | undefined,
    endDate: string | null | undefined,
    format: KunstenDateFormat,
    period: boolean
) {
    const { formatDate } = useFormatDate();

    const formattedEsDate = computed(() => {
        if (!startDate && !endDate) return "";

        if (period && startDate && endDate) {
            return `${formatDate(startDate, format)} - ${formatDate(endDate, format)}`;
        }

        return formatDate(endDate, format) || formatDate(startDate, format) || "";
    });

    return { formattedEsDate };
}
