
import { defineComponent, PropType } from "vue";
import { EsArtworkDto } from "@kunsten/core";
import ArtworkCard from "./ArtworkCard.vue";
import Slider from "./Slider.vue";
import TitleYearLabel from "./TitleYearLabel.vue";

export default defineComponent({
    name: "ArtworkSlider",
    components: { ArtworkCard, Slider, TitleYearLabel },
    props: {
        entities: {
            type: Array as PropType<EsArtworkDto[]>,
            default: () => [],
        },
    },
});
