/* eslint-disable @typescript-eslint/no-explicit-any */

export type ArrayElement<U> = U extends Array<infer K> ? K : never;

/**
 * This property contains list of all filter keys that are used inside selects and
 */
export const SELECTS_FILTER_KEYS = [
    "artType" as const,
    "creatorsGenders" as const,
    "creatorsNames" as const,
    "creatorsNationalities" as const,
    "materials" as const,
    "technique" as const,
];

export type SelectFilterKeys = ArrayElement<typeof SELECTS_FILTER_KEYS>;

const DATE_FILTER_KEYS_PREFIX = ["productionStartDate" as const, "productionEndDate" as const];
const DATE_FILTER_KEYS_SUFFIX = [
    "before" as const,
    "after" as const,
    "strictly_before" as const,
    "strictly_after" as const,
];

type DateFilterBaseKeys = ArrayElement<typeof DATE_FILTER_KEYS_PREFIX>;
type DateFilterKeys__suffix = ArrayElement<typeof DATE_FILTER_KEYS_SUFFIX>;
export type DateFilterKeys = `${DateFilterBaseKeys}[${DateFilterKeys__suffix}]`;

export const DATE_FILTER_KEYS: Array<DateFilterKeys> = DATE_FILTER_KEYS_PREFIX.flatMap((prefix) => {
    return DATE_FILTER_KEYS_SUFFIX.map((suffix) => `${prefix}[${suffix}]` as const);
});

export type DateFiltersObject = Partial<Record<DateFilterKeys, string>>;

export interface FilterOptionTerms<T> {
    terms: T[];
}

export type FilterOptionTermValue<T = string> = FilterOptionTerms<{
    value: T;
    count: number;
}>;

export type FilterOptionNumericTermValue = FilterOptionTermValue<number>;

export interface FilterOptionRangeValue {
    min: string | null;
    max: string | null;
}

/**
 * This type describes options which are returned from search api in hydra:es:aggregation
 */
/* eslint-disable prettier/prettier */
// prettier-ignore
export interface FilterOptions
    extends Record<SelectFilterKeys, FilterOptionTermValue>,
            Record<DateFilterBaseKeys, FilterOptionRangeValue> {
    onDisplay: FilterOptionNumericTermValue;
    [index: string]: FilterOptionTermValue | FilterOptionNumericTermValue | FilterOptionRangeValue;
}
/* eslint-enable prettier/prettier */

export type SelectFiltersObject = Partial<Record<SelectFilterKeys, string[]>>;

/**
 * This type describes filters data options that are passed to the api
 */
export interface FiltersData extends DateFiltersObject, SelectFiltersObject {
    lang: string;
    q?: string;
    onDisplay?: boolean;
    [key: string]: string[] | string | boolean | undefined; // index signature added for convenience
}

/**
 * This property contains all supported keys, updating the keys above needs to
 * result in updating this list, otherwise persistance of filters data will be
 * lost since only those keys are used when syncing from route and memory cache
 */
export const ALL_FILTER_KEYS: Array<keyof FiltersData> = ["lang", "q", "onDisplay"]
    .concat(SELECTS_FILTER_KEYS)
    .concat(DATE_FILTER_KEYS);

export function isFilterOptionTermValue<T = string>(value: unknown): value is FilterOptionTermValue<T> {
    return typeof value === "object" && value !== null && "terms" in value && Array.isArray((value as any).terms);
}

export function isFilterOptionRangeValue(value: unknown): value is FilterOptionRangeValue {
    return (
        typeof value === "object" &&
        value !== null &&
        typeof (value as any).min === "string" &&
        typeof (value as any).max === "string"
    );
}
