
import { defineComponent } from "vue";
import getEntityTranslation from "../../modules/entity/get-entity-translation";
import { useTextLimit } from "@kunsten/core";

export default defineComponent({
    name: "Translation",
    props: {
        data: {
            type: Object,
            required: true,
        },
        property: {
            type: String,
            required: true,
        },
        limit: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    computed: {
        translation() {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const translation = getEntityTranslation<any>(this.data, this.property);

            return useTextLimit(translation, this.limit);
        },
    },
});
