import { TranslatableEntity } from "../dto/translatable-entity";
import { useAPI } from "../api";
import { useApiResource } from "../api-resource";
import axios, { AxiosResponse } from "axios";
import { Whole } from "../util/types";
import { HydraCollection } from "../dto/hydra";

export const TAXONOMIES = ["art_types", "genders", "materials", "nationalities", "techniques"];

export interface PatchTranslatableEntityData<T> {
    enTranslation: T;
}

export interface TranslatableEntityParams {
    page: string;
    pagination?: string;
}

export function useTaxonomies() {
    const { http } = useAPI();

    const getTaxonomies = (
        taxonomy: string,
        params: TranslatableEntityParams
    ): Promise<AxiosResponse<HydraCollection<TranslatableEntity<string>>>> => {
        return http.get<HydraCollection<TranslatableEntity<string>>>(`/api/${taxonomy}`, { params });
    };

    const getTaxonomy = (taxonomy: string, id: string): Promise<AxiosResponse<TranslatableEntity<string>>> => {
        return http.get<TranslatableEntity<string>>(`/api/${taxonomy}/${id}`);
    };

    const patchTaxonomy = (
        taxonomy: string,
        id: string,
        data: PatchTranslatableEntityData<string>
    ): Promise<AxiosResponse<Whole<PatchTranslatableEntityData<string>>>> => {
        return http.patch<PatchTranslatableEntityData<string>>(`/api/${taxonomy}/${id}`, data);
    };

    const getAllTaxonomies = () => {
        return axios.all(
            TAXONOMIES.map((taxonomy) => {
                return getTaxonomies(taxonomy, { page: "1", pagination: "false" });
            })
        );
    };

    return {
        getTaxonomies,
        getTaxonomy,
        patchTaxonomy,
        getAllTaxonomies,
    };
}

export function useTaxonomiesResource(taxonomy: string, params: TranslatableEntityParams) {
    return useApiResource<TranslatableEntity<string>, TranslatableEntityParams>(`/api/${taxonomy}`, params);
}
