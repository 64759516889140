import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3577fd94"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "label",
  "data-testid-label": ""
}
const _hoisted_2 = {
  key: 0,
  class: "sublabel mt-5",
  "data-testid-label-sublabel": ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["label-title", _ctx.size]),
        "data-testid-label-title": ""
      }, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["label-subtitle", _ctx.subSize]),
        "data-testid-label-subtitle": ""
      }, [
        _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
      ], 2)
    ]),
    (!!_ctx.$slots.sublabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "sublabel", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}