import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import core from "./core";
import i18n from "./i18n";
import shared from "./shared";
import "./styles/styles.scss";
import "@fortawesome/fontawesome-free/css/all.css";

const app = createApp(App).use(store).use(router).use(i18n).use(core, { store }).use(shared);

// route guard
router.beforeEach((to, from, next) => {
    const { isAuthenticated } = core.api;

    if (!to.meta.unauthenticated && !isAuthenticated.value) {
        return next({ name: "LandingPage", query: { view: "login", redirect: to.fullPath } });
    }

    return next();
});

app.mount("#app");
