
import { ArtworkDto, EsArtworkDto, useFormattedDate, useFormattedEsDate } from "@kunsten/core";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
    name: "TitleYearLabel",
    props: {
        artwork: {
            type: Object as PropType<EsArtworkDto | ArtworkDto>,
            required: true,
        },
    },
    setup(props) {
        const title = computed(() => {
            if (!props.artwork) return "";
            return props.artwork?.title || "";
        });

        const { formattedDate } = useFormattedDate((props.artwork as ArtworkDto)?.productionDate, "year", false);
        const { formattedEsDate } = useFormattedEsDate(
            (props.artwork as EsArtworkDto)?.productionStartDate,
            (props.artwork as EsArtworkDto)?.productionEndDate,
            "year",
            false
        );

        const year = computed(() => {
            if (formattedDate.value) return `(${formattedDate.value})`;
            if (formattedEsDate.value) return `(${formattedEsDate.value})`;
            return "";
        });

        return { title, year };
    },
});
