import { onMounted, onUnmounted, onUpdated } from "vue";
import { nextTick } from "vue";

export function useScrollTransition() {
    const onScrollTransition = () => {
        const cards = document.querySelectorAll(".scroll-transition:not(.active)");
        if (!cards.length) return;
        const height = window.innerHeight;
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].getBoundingClientRect().top < height) {
                cards[i].classList.add("active");
            }
        }
    };

    onMounted(() => {
        onScrollTransition();
        window.addEventListener("scroll", onScrollTransition);
    });

    onUnmounted(() => {
        window.removeEventListener("scroll", onScrollTransition);
    });

    onUpdated(() => {
        nextTick().then(() => {
            onScrollTransition();
        });
    });
}
