
import { UserCollectionDto } from "@kunsten/core";
import { computed, defineComponent, PropType } from "vue";
import BlankImage from "../../../shared/components/BlankImage.vue";

export default defineComponent({
    name: "CollectionBlockCollage",
    components: { BlankImage },
    props: {
        collection: {
            type: Object as PropType<UserCollectionDto>,
            required: true,
        },
    },
    setup(props) {
        const images = computed(() => {
            const artworks = props.collection.artworks;
            if (!artworks.length) return [];
            let reproductions = [] as string[];
            artworks.forEach((a) => {
                if (a.artwork?.reproductions.length) {
                    reproductions = [...reproductions, a.artwork.reproductions[0]];
                }
            });
            return reproductions.length > 3 ? reproductions.slice(0, 3) : reproductions;
        });

        return { images };
    },
});
