import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1aea780a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "icon"
}
const _hoisted_2 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["icon-text", [{ dark: _ctx.dark }, _ctx.size]])
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          size: "xs",
          class: "mr-2"
        }))
      : (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
        ])),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "text", {}, undefined, true)
    ])
  ], 2))
}