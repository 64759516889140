<template>
    <div v-if="message" class="alert">
        {{ message }}
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "Alert",
    props: {
        message: String,
    },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.alert {
    color: $kun-error;
    font-size: 0.875rem;
}
</style>
