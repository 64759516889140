
import { defineComponent, computed } from "vue";

import HomeSection from "../shared/components/HomeSection.vue";
import ThemesSection from "../shared/components/ThemesSection.vue";
import PopularCollections from "../../../shared/components/PopularCollections.vue";
import OnDisplaySection from "../shared/components/OnDisplaySection.vue";
import LoginPopup from "./LoginPopup.vue";
import RegisterPopup from "./RegisterPopup.vue";
import ForgotPasswordPopup from "./ForgotPasswordPopup.vue";
import CreatePasswordPopup from "./CreatePasswordPopup.vue";
import { useRoute } from "vue-router";
import { useDocumentTitle } from "@kunsten/core";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "LandingPage",
    components: {
        HomeSection,
        OnDisplaySection,
        PopularCollections,
        ThemesSection,
        LoginPopup,
        RegisterPopup,
        ForgotPasswordPopup,
        CreatePasswordPopup,
    },
    setup() {
        const route = useRoute();
        const view = computed(() => route.query.view);
        const { t } = useI18n();

        useDocumentTitle(t("general.base-title"));

        return { view };
    },
});
