import { MyCollectionPageDto } from "../dto/my-collection-page";
import { useAPI } from "../api";
import { AxiosResponse } from "axios";
import { useApiResource } from "../api-resource";

export interface MyCollectionPagesQueryParams {
    page: string;
    pagination?: string;
    "order[daTranslation.title]"?: string;
    "order[enTranslation.title]"?: string;
}

export function useMyCollectionPages() {
    const { http } = useAPI();

    const getMyCollectionPages = (): Promise<AxiosResponse<MyCollectionPageDto>> => {
        return http.get<MyCollectionPageDto>(`/api/my_collection_pages`);
    };

    const getMyCollectionPage = (id: string): Promise<AxiosResponse<MyCollectionPageDto>> => {
        return http.get<MyCollectionPageDto>(`/api/my_collection_pages/${id}`);
    };

    const patchMyCollectionPages = (
        data: Partial<MyCollectionPageDto>
    ): Promise<AxiosResponse<MyCollectionPageDto>> => {
        return http.patch<MyCollectionPageDto>(`/api/my_collection_pages`, data);
    };

    const postMyCollectionPages = (data: MyCollectionPageDto): Promise<AxiosResponse<MyCollectionPageDto>> => {
        return http.post<MyCollectionPageDto>(`/api/my_collection_pages`, data);
    };

    return {
        getMyCollectionPages,
        getMyCollectionPage,
        patchMyCollectionPages,
        postMyCollectionPages,
    };
}

export function useMyCollectionPagesResource(params: MyCollectionPagesQueryParams) {
    return useApiResource<MyCollectionPageDto, MyCollectionPagesQueryParams>(`/api/my_collection_pages`, params);
}
