import { useAPI } from "../api";
import { AxiosResponse } from "axios";

export function useResetPasswordRequest() {
    const { http } = useAPI();

    function resetPasswordRequest(email: string): Promise<AxiosResponse<void>> {
        return http.post<void>(`/api/users/reset-password-request`, { email }).then((res) => {
            return res;
        });
    }

    return {
        resetPasswordRequest,
    };
}
