import { useAPI } from "../api";
import { AxiosResponse } from "axios";

export function useCreatePassword() {
    const { http } = useAPI();

    const createPassword = (password: string, token: string): Promise<AxiosResponse<void>> => {
        return http.post<void>(`/api/users/create-password`, { token, password }).then((res) => {
            return res;
        });
    };

    return {
        createPassword,
    };
}
