
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "SkeletonLoader",
    props: {
        imgSrc: {
            type: String,
            required: true,
        },
    },
    setup() {
        const loaded = ref(false);
        return { loaded };
    },
});
