import { App } from "vue";
import { AxiosInstance } from "axios";
import { createApi, KUNSTEN_API_KEY, KUNSTEN_HTTP_KEY, KunstenApi } from "./api";
import HasRole from "./auth/HasRole.vue";
import IsAuthenticated from "./auth/IsAuthenticated.vue";
import HasAdminRole from "./auth/HasAdminRole.vue";
import TrapFocusDirective from "./util/trap-focus";
import ClickedOutside from "./util/ClickedOutside.vue";
import { Store } from "vuex";
import { createNotifications, NotificationsPlugin, NOTIFICATIONS_KEY } from "./notifications";

export interface CoreOptions {
    store: Store<unknown>;
}

export default function createCore() {
    const api = createApi();
    const notifications = createNotifications();

    return {
        api,
        notifications,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        install(app: App, { store }: CoreOptions) {
            app.provide(NOTIFICATIONS_KEY, notifications);
            app.config.globalProperties.$notifications = notifications;

            app.provide(KUNSTEN_API_KEY, api);
            app.config.globalProperties.$api = api;

            app.provide(KUNSTEN_HTTP_KEY, api.http);
            app.config.globalProperties.$http = api.http;

            app.component("HasRole", HasRole);
            app.component("IsAuthenticated", IsAuthenticated);
            app.component("HasAdminRole", HasAdminRole);

            app.component("ClickedOutside", ClickedOutside);
            app.directive("trap-focus", TrapFocusDirective);
        },
    };
}

export type { KunstenApi };
export { useHttp, useAPI } from "./api";
export { useLogin } from "./auth/login";
export { useRegister } from "./auth/register";
export { useCreatePassword } from "./auth/create-password";
export { useResetPasswordRequest } from "./auth/reset-password-request";
export { useApiResource, useSingleResource, useDeleteResource } from "./api-resource";
export { default as HasRole } from "./auth/HasRole.vue";
export { default as IsAuthenticated } from "./auth/IsAuthenticated.vue";
export { default as IsNotAuthenticated } from "./auth/IsNotAuthenticated.vue";
export { default as IsOwnedByUser } from "./auth/IsOwnedByUser.vue";
export type { SocialMediaType } from "./auth/login";

// export entities methods
export { useUsersResource, useUsers, USER_ROLES } from "./entities/user";
export { useUserCollectionsResource, useUserCollections } from "./entities/user-collection";
export { useUserCollectionsSearch } from "./entities/user-collection-search";
export { useAcquisitionsResource, useAcquisitions } from "./entities/acquisition";
export { useArtistsResource, useArtists } from "./entities/artist";
export { useArtworksResource, useArtworks } from "./entities/artwork";
export { useArtworkContentsResource, useArtworkContents } from "./entities/artwork-content";
export { useExhibitionsResource, useExhibitions } from "./entities/exhibition";
export { usePeriodsResource, usePeriods } from "./entities/period";
export { useTaxonomiesResource, useTaxonomies } from "./entities/translatable-entity";
export { useThemesResource, useThemes } from "./entities/theme";
export { useTagsResource, useTags } from "./entities/tag";
export { usePresavedSearchesResource, usePresavedSearches } from "./entities/presaved-search";
export { useMainPhotosResource, useMainPhotos } from "./entities/main-photo";
export { useImages } from "./entities/image";
export { useAboutCollections, useAboutCollectionsResource } from "./entities/about-collection-page";
export { useMyCollectionPages, useMyCollectionPagesResource } from "./entities/my-collection-page";
export * from "./search/useSearch"; // here we want to export everything including types
export { useFilterOptions } from "./search/useFilterOptions";
export { useMemoryCachedSearch, purgeSearchMemoryCache } from "./search/useMemoryCachedSearch";

// export useful utilities
export { useLocalStorage } from "./util/local-storage";
export { useColumnView } from "./util/column-view";
export { useDocumentTitle } from "./util/document-title";
export { useScrollTransition } from "./util/scroll-transition";
export { useViewport, BREAKPOINTS } from "./util/viewport";
export { useFormattedDate, useFormattedEsDate } from "./util/formatted-date";
export { useGettersAndSetters } from "./util/getters-and-setters";
export { useMobileKeyboard } from "./util/mobile-keyboard";
export { useTokenCredentials } from "./util/token-credentials";
export type { KunstenDateFormat } from "./util/formatted-date";
export type { BreakpointsMap, BreakpointName, UseViewport } from "./util/viewport";
export { Deferred } from "./util/deferred";
export { identity } from "./util/identity";
export { useTextLimit } from "./util/text-limit";

// Export useful types
export * from "./util/types";
export * from "./dto/user";
export * from "./dto/user-collection";
export * from "./dto/my-collection-page";
export * from "./dto/token";
export * from "./dto/hydra";
export * from "./dto/date";
export * from "./dto/translatable-entity";
export * from "./dto/acquisition";
export * from "./dto/artwork";
export * from "./dto/about-collection-page";
export * from "./dto/es-artwork";
export * from "./dto/exhibition";
export * from "./dto/artist";
export * from "./dto/theme";
export * from "./dto/image";
export * from "./dto/tag";
export * from "./dto/presaved-search";
export * from "./search/filters";
export * from "./dto/page";

// Notifications

export { useNotifications, createNotifications, createNotificationsMock } from "./notifications";
export type { NotificationsPlugin, NotificationsPluginMock, Notification, NotificationType } from "./notifications";

declare module "@vue/runtime-core" {
    export interface ComponentCustomProperties {
        $api: KunstenApi;
        $http: AxiosInstance;
        $notifications: NotificationsPlugin;
    }
}
